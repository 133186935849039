import * as Yup from 'yup';
import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Field, Formik, Form, FormikHelpers } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useDispatch } from 'react-redux';
import { authActions } from 'app/store/auth';

interface IForcePasswordResetProps {
  name: string;
  logoUrl: string;
}

type TPasswordForm = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

const PasswordFormSchema = Yup.object().shape({
  current_password: Yup.string().trim().required('This field is required'),
  new_password: Yup.string().trim().required('This field is required'),
  confirm_password: Yup.string()
    .trim()
    .required('This field is required')
    .when('new_password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('new_password')], 'Passwords do not match'),
    }),
});

const ForcePasswordReset: FC<IForcePasswordResetProps> = (props) => {
  const { name, logoUrl } = props;

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const passwordUpdateMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/profile/password`,
      payload,
    );

    return data;
  });

  const passwordFormInitialValues: TPasswordForm = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };

  const handleSubmit = async (
    values: TPasswordForm,
    formikHelpers: FormikHelpers<TPasswordForm>,
  ) => {
    try {
      const data = await passwordUpdateMutation.mutateAsync({
        password: values.current_password,
        newPassword: values.new_password,
      });
      formikHelpers.resetForm();
      queryClient.fetchQuery(['profileData']);

      dispatch(
        authActions.login({
          token: data.token,
          rememberMe: true,
        }),
      );

      toast.success('Password changed successfully');
    } catch (ex: any) {
      const errorMsg = ex.data?.message
        ? ex.data?.message
        : 'Something went wrong';
      toast.error(errorMsg);
      console.log(ex);
    }
  };

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img src={logoUrl} className='h-12' alt='' />
        </div>

        <div className='flex justify-center gap-0 md:gap-6'>
          <div
            className={`card shadow-lg rounded-lg  gap-3 overflow-hidden mt-10 w-[600px]`}
          >
            <div className='px-10 py-10'>
              <div>
                <h3 className='font-bold'>Password Change Required</h3>
                <div className='text-base text-gray-800 font-light mt-3'>
                  You need to change password before you continue
                </div>
                <Formik
                  initialValues={passwordFormInitialValues}
                  validationSchema={PasswordFormSchema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div>
                        <div className='mt-8'>
                          <div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='current_password'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Current Password
                              </label>
                              <Field
                                id='current_password'
                                name='current_password'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='current_password' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='new_password'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                New Password
                              </label>
                              <Field
                                id='new_password'
                                name='new_password'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='new_password' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='confirm_password'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Confirm Password
                              </label>
                              <Field
                                id='confirm_password'
                                name='confirm_password'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='confirm_password' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='mt-20'>
                          <LoaderButton
                            type='submit'
                            className='bg-primary text-white text-sm font-semibold rounded-lg w-full '
                            isLoading={passwordUpdateMutation.isLoading}
                            disabled={passwordUpdateMutation.isLoading}
                          >
                            Confirm
                          </LoaderButton>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForcePasswordReset;
