import { FC } from 'react';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { createAmendment } from 'app/api/services/amendment.service';
import { toast } from 'react-toastify';

interface IRaiseAmendmentDialog {
  show: boolean;
  onClose: () => void;
  bookingId: string;
}

const formSchema = Yup.object({
  type: Yup.string().trim().required('This field is required'),
  message: Yup.string().trim().required('This field is required'),
});

const RaiseAmendmentDialog: FC<IRaiseAmendmentDialog> = (props) => {
  const { user } = useSelector((state: TRootState) => state.auth);

  const handleSubmit = async (value: { message: string; type: string }) => {
    try {
      const { data } = await createAmendmentMutation.mutateAsync({
        bookingId: props.bookingId,
        amendment: value,
      });
      toast.success('Amendment Raised ');
      props.onClose();
    } catch (e:any) {
      toast.error(e?.data?.message || "Something went wrong");
      console.log('Something went wrong');
    }
  };

  const createAmendmentMutation = useMutation(
    async (values: {
      bookingId: string;
      amendment: {
        message: string;
        type: string;
      };
    }) => createAmendment(values),
  );

  return (
    <Modal className='px-10 py-10 ' show={props.show} onClose={props.onClose}>
      <div className='font-bold text-lg'>Raise Ammendment</div>
      <div className='mt-4'>
        <Formik
          initialValues={{
            type: '',
            message: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {({ values, errors }) => (
            <Form>
              <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12  md:col-span-12'>
                  <label className='text-xs mb-2 label-required' htmlFor='type'>
                    Amendment Type
                  </label>
                  <Field
                    as='select'
                    name='type'
                    type='text'
                    id='type'
                    className='form-control'
                  >
                    <option key={''} value={''} disabled>
                      Select option
                    </option>
                    {user.agencySettings.amendmentTypes.map((amendmentType) => (
                      <option key={amendmentType} value={amendmentType}>
                        {amendmentType}
                      </option>
                    ))}
                  </Field>
                  <div className='form-error'>
                    <ErrorMessage name='type' />
                  </div>
                </div>
                <div className='col-span-12 '>
                  <label
                    className='text-xs mb-2 label-required'
                    htmlFor='message'
                  >
                    Message
                  </label>
                  <Field
                    as='textarea'
                    name='message'
                    type='text'
                    id='message'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='message' />
                  </div>
                </div>
                <div className='col-span-12 flex justify-center gap-4 mt-6'>
                  <LoaderButton
                    type='submit'
                    className='bg-red-500 text-white px-6 text-sm rounded-lg'
                    isLoading={createAmendmentMutation.isLoading}
                  >
                    Raise Amendment
                  </LoaderButton>
                  <button
                    className='bg-gray-200 text-gray-600 px-6 text-sm rounded-lg'
                    onClick={props.onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RaiseAmendmentDialog;
