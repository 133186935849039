import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice, { authListenerMiddleware } from './auth';
import accountSlice from './account';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  account: accountSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(authListenerMiddleware.middleware),
});

export type TRootState = ReturnType<typeof rootReducer>;


export default store;
