import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';
import { getInitials } from 'app/utils/common-utils';
import { AMENDMENT_STATUS } from 'app/utils/constants';
import { formatCurrency } from 'app/utils/currency-utils';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { FC, useLayoutEffect, useRef } from 'react';

interface MessageInterface {
  type: 'message' | 'action';
  by: { name: string };
  content: string;
  createdAt: string;
  updatedAt: string;
  userType: 'SubAgencyUser' | 'AgencyUser';
}

interface RequestConversationProps {
  bookingId: string;
  ammendmentId: string;
}

const RequestConversation: FC<RequestConversationProps> = (props) => {
  const { bookingId, ammendmentId } = props;
  const queryClient = useQueryClient();
  const chatBox = useRef<HTMLDivElement>(null);

  const amendmentQuery = useQuery(
    ['amendment', bookingId, ammendmentId],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments/${ammendmentId}`,
      );
      return data;
    },
  );

  const conversationQuery = useQuery(
    ['amendment', 'chat', bookingId, ammendmentId],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments/${ammendmentId}/messages`,
      );
      return data;
    },
  );

  const markupMutation = useMutation(async (values: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments/${ammendmentId}/message`,
      values,
    );

    return data;
  });

  const handleSubmit = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      await markupMutation.mutateAsync({
        ...values,
      });
      formikHelpers.resetForm();
      queryClient.fetchQuery(['amendment', 'chat', bookingId, ammendmentId]);
      queryClient.fetchQuery(['amendment', bookingId, ammendmentId]);
    } catch (ex: any) {
      // toast.error(ex?.data?.message || 'Some error occured, please try again.');
    } finally {
      // onClose();
    }
  };

  const rejectMutation = useMutation(async () => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments/${ammendmentId}/reject-change`,
    );
    return data;
  });

  const approveMutation = useMutation(async () => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments/${ammendmentId}/approve-change`,
    );
    return data;
  });

  const handleReject = async () => {
    await rejectMutation.mutateAsync();
    queryClient.fetchQuery(['amendment', 'chat', bookingId, ammendmentId]);
    queryClient.fetchQuery(['amendment', bookingId, ammendmentId]);
  };

  const handleAccept = async () => {
    await approveMutation.mutateAsync();
    queryClient.fetchQuery(['amendment', 'chat', bookingId, ammendmentId]);
    queryClient.fetchQuery(['amendment', bookingId, ammendmentId]);
  };

  useLayoutEffect(() => {
    if (chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  });

  if (amendmentQuery.isLoading || conversationQuery.isLoading)
    return (
      <div className='flex justify-center items-center pt-14'>
        <Spinner />
      </div>
    );

  const amendment = amendmentQuery.data;
  const conversation = conversationQuery.data;

  return (
    <div className='pt-8 mx-8 flex flex-col h-[inherit]'>
      <div>
        <div className='grid grid-cols-6 mt-2'>
          <div className='col-span-2 sm:col-span-1 text-sm leading-4 font-semibold '>
            Request ID
          </div>
          <div className='col-span text-sm leading-4 font-normal text-blue-500'>
            {amendment.id}
          </div>
        </div>
        <div className='grid grid-cols-6 mt-2'>
          <div className='col-span-2 sm:col-span-1 text-sm leading-4 font-semibold'>
            Request By
          </div>
          <div className='col-span-4 sm:col-span-5 text-sm leading-4 font-normal'>
            {amendment.raisedBy}
          </div>
        </div>
        <div className='grid grid-cols-6 mt-2'>
          <div className='col-span-2 sm:col-span-1 text-sm leading-4 font-semibold'>
            Date & Time
          </div>
          <div className='col-span-4 sm:col-span-5 text-sm leading-4 font-normal'>
            {`${moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')} at
                        ${moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}`}
          </div>
        </div>
        <div className='grid grid-cols-6 mt-2'>
          <div className='col-span-2 sm:col-span-1 text-sm leading-4 font-semibold'>
            Change Type
          </div>
          <div className='col-span-4 sm:col-span-5 text-sm leading-4 font-normal'>
            {capitalize(amendment.type.toLowerCase())}
          </div>
        </div>
        <div className='grid grid-cols-6 mt-2'>
          <div className='col-span-2 sm:col-span-1 text-sm leading-4 font-semibold'>
            Passengers
          </div>
          <div className='col-span-4 sm:col-span-5 text-sm leading-4 font-normal'>
            -
          </div>
        </div>
        <div className='text-sm font-bold mt-2'>Conversation</div>
      </div>

      <div className='flex-grow overflow-y-auto mt-2' ref={chatBox}>
        <div className='flex flex-col   overflow-auto scroll-smooth pr-4 '>
          {conversation.map((message: MessageInterface, index: number) => {
            if (message.type === 'message') {
              return (
                <div
                  key={`${message.createdAt}`}
                  className={`mt-6 ${
                    message.userType === 'SubAgencyUser'
                      ? 'self-end ml-20'
                      : 'self-start mr-20'
                  }`}
                >
                  <div
                    className={`inline-block p-4 pt-3  rounded-3xl w-full  text-sm leading-4 font-normal     
                                ${message.userType === 'SubAgencyUser' ? 'bg-[#0067FF] text-white' : 'bg-[#D9D9D9]'}
                                `}
                  >
                    {message.content}
                  </div>
                  <div className='mt-4 flex gap-2 justify-start items-center'>
                    <div className='w-5 h-5 bg-primary/10 rounded-full flex items-center justify-center text-[.5rem]'>
                      <div className='text-primary font-semibold'>
                        {getInitials(message.by.name)}
                      </div>
                    </div>
                    <div className='text-xs leading-[14px] font-normal'>
                      {`${message.by.name} at ${moment(
                        message.createdAt,
                        'YYYYMMDDHHmmss',
                      ).format('hh:mm A - DD MMM, YY')}`}
                    </div>
                  </div>
                </div>
              );
            }

            if (message.type === 'action') {
              return (
                <div
                  key={`${message.createdAt}`}
                  className={`mt-6 self-center px-4 group`}
                >
                  <div
                    className={`inline-block p-4 pt-3  rounded-3xl w-full  text-sm leading-4 font-normal bg-yellow-100`}
                  >
                    {message.content}
                  </div>
                  {/* <div className="mt-4 flex gap-2 justify-start items-center hidden group-hover:flex">
                                        <div className="w-5 h-5 bg-primary/10 rounded-full flex items-center justify-center text-[.5rem]">
                                            <div className="text-primary font-semibold">
                                                {getInitials(message.by.name)}
                                            </div>
                                        </div>
                                        <div className="text-xs leading-[14px] font-normal">
                                            {`${message.by.name} at ${moment(
                                                message.createdAt,
                                                'YYYYMMDDHHmmss'
                                            ).format('hh:mm A - DD MMM, YY')}`}
                                        </div>
                                    </div> */}
                </div>
              );
            }

            return '';
          })}
        </div>
      </div>
      {/* send message */}
      {amendment.status !== AMENDMENT_STATUS.COMPLETED &&
        amendment.status !== AMENDMENT_STATUS.REJECTED && (
          <div>
            {/* message section */}
            <div className='mt-8'>
              <Formik initialValues={{ message: '' }} onSubmit={handleSubmit}>
                <Form>
                  <div className='flex gap-4'>
                    <Field
                      name='message'
                      type='text'
                      className='w-full rounded-3xl border outline-none px-4 py-3 border-[#6F6F6F] '
                      placeholder='Write a reply...'
                    />
                    <button
                      type='submit'
                      className='text-sm font-semibold bg-primary text-white px-6 py-4 rounded-3xl'
                    >
                      Send
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
            {/* line */}
            {amendment.amount && (
              <div>
                <div className=' mt-6 border bottom-1 border-gray-200' />
                {/* amount decided */}
                <div>
                  <div className='flex mt-6 justify-between gap-5'>
                    <div className='flex items-center gap-4 text-lg leading-5 font-semibold'>
                      {/* <div>Total Change Fee </div> */}
                      {amendment.noteType === 'DEBIT' && (
                        <div> You will be charged </div>
                      )}
                      {amendment.noteType === 'CREDIT' && (
                        <div> You will be paid </div>
                      )}
                      <div>
                        <div className='flex items-center gap-4'>
                          <div className=''>
                            {formatCurrency(amendment.amount)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {amendment.status ===
                      AmendmentStatusEnum.AWAITING_SUB_AGENCY_APPROVAL && (
                      <div className='flex gap-4'>
                        <LoaderButton
                          isLoading={approveMutation.isLoading}
                          onClick={handleAccept}
                          type='submit'
                          className='text-white bg-green-500 px-4 py-3 rounded-md text-base leading-5 font-semibold '
                        >
                          Approve Change
                        </LoaderButton>
                        <LoaderButton
                          isLoading={rejectMutation.isLoading}
                          onClick={handleReject}
                          className='text-white bg-red-500 px-4 py-3 rounded-md text-base leading-5 font-semibold '
                        >
                          Reject Change
                        </LoaderButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default RequestConversation;
