import axios from 'axios';

// axios instance for making requests
const noAuthHttp = axios.create({
  timeout: 150000,
});

// request interceptor for adding token
noAuthHttp.interceptors.request.use((config) => {
  config.headers['aystav'] = 'awhsa';
  return config;
});

export default noAuthHttp;
