/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnimatePresence, motion } from 'framer-motion';
import capitalize from 'lodash.capitalize';
import { FC, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  formatCurrency,
  formatCurrencyWithOutDecimal,
} from 'app/utils/currency-utils';
import { formatMinutesToTime, formatTime } from 'app/utils/date-utils';
import FlightDetailTab from '../FlightDetailTab/FlightDetailTab';
import { useContext } from 'react';
import FlightsContext from '../../context/flights-context';
import { calculateStops, getFormattedStops } from 'app/utils/flight-utils';
import { IFlight } from 'app/types';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import FlightDetailModal from '../FlightDetailModal/FlightDetailModal';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import { ChairIcon } from 'app/icons';
import useCtrlShiftOHover from './useShiftHook';
import { BOOKING_FLOW_ID } from 'app/utils/constants';

const ulAnimation = {
  variants: {
    collapsed: { opacity: 0 },
    expanded: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  },
  initial: 'collapsed',
  animate: 'expanded',
};

const liAnimation = {
  variants: {
    collapsed: { opacity: 0, height: 0 },
    expanded: { opacity: 1, height: 'auto' },
  },
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
};

const collapseAnimation = {
  variants: {
    collapsed: {
      opacity: 0,
      height: 0,
    },
    expanded: {
      opacity: 1,
      height: 'auto',
    },
  },
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
};

interface FlightListItemProps extends IFlight {
  index: number;
  createEvent: (payload: {
    priceListIndex: number;
    selectedPriceItemIndex: number;
    type: 'SELECTED' | 'BOOKED';
  }) => void;
}

const FlightListItem: FC<FlightListItemProps> = (props) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const {
    index,
    segments,
    priceList,
    departure,
    arrival,
    duration,
    createEvent,
  } = props;
  const { user } = useSelector((state: TRootState) => state.auth);
  const displayFirst = user.agency.fareDisplayCount;
  const navigate = useNavigate();
  const { passengers, showNetFare, subAgencyAdditionalMarkup, bookingFlowId } =
    useContext(FlightsContext);
  const [priceListIndex, setPriceListIndex] = useState<number>(displayFirst);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedPriceItemIndex, setSelectedPriceItemIndex] =
    useState<number>(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isMobile) {
      setPriceListIndex(1);
    }
  }, []);

  const airiqBookingMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/ticket-details`,
      payload,
    );
    return data;
  });

  const getFlightNames = () => {
    const flightNames = segments.map((s) => s.flight.name);

    if (flightNames.every((v: string) => v === flightNames[0]))
      return flightNames[0];

    return flightNames[0] + ' (+1)';
  };

  const getFlightNumbers = () => {
    if (segments.length === 1)
      return `${segments[0].flight.code} ${segments[0].flight.number}`;

    return `${segments[0].flight.code} ${segments[0].flight.number}  (+1)`;
  };

  const togglePriceListIndex = () => {
    if (priceListIndex !== displayFirst) {
      setPriceListIndex(displayFirst);
    } else setPriceListIndex(priceList.length);
  };

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleAiriq = async () => {
    const priceDetails = priceList[selectedPriceItemIndex];

    // console.log({ priceDetails });

    const handleTotalCalculation = () => {
      let totalFare = priceDetails.totalFare; // recived at root
      let totalBaseFare = priceDetails.totalFare; // same as total fare
      let totalTaxesAndFees = 0; // always zero air iq

      // if (priceDetails.fareDescription.adult) {
      //     totalFare += priceDetails.fareDescription.adult.fareComponent.total * passengers.adult;
      //     totalBaseFare += priceDetails.fareDescription.adult.fareComponent.base * passengers.adult;
      //     //totalTaxesAndFees += priceDetails.fareDescription.adult.fareComponent.taxesAndFees * passengers.adult;
      // }
      // if (priceDetails.fareDescription.adult && passengers.child > 0) {
      //     totalFare += priceDetails.fareDescription.adult.fareComponent.total * passengers.child;
      //     totalBaseFare += priceDetails.fareDescription.adult.fareComponent.base * passengers.child;
      //     // totalTaxesAndFees += priceDetails.fareDescription.adult.fareComponent.taxesAndFees * passengers.child;
      // }
      // if (priceDetails.fareDescription.infant && passengers.infant > 0) {
      //     totalFare += priceDetails.fareDescription.infant.fareComponent.total * passengers.infant;
      //     totalBaseFare += priceDetails.fareDescription.infant.fareComponent.base * passengers.infant;
      //     //totalTaxesAndFees += priceDetails.fareDescription.infant.fareComponent.taxesAndFees * passengers.infant;
      // }

      return {
        totalFare,
        totalBaseFare,
        totalTaxesAndFees,
      };
    };

    const { totalBaseFare, totalTaxesAndFees, totalFare } =
      handleTotalCalculation();

    const newData = {
      stops: 'non-stop',
      fareSupplier: '3',
      departure,
      arrival,
      duration,
      bookingId: priceDetails.id,
      segments: segments.map((segment) => {
        return {
          ...segment,
          layoverDuration: 0,
          sNumber: 0,
          stops: 0,
          stopsOverAirports: [],
          operatingAirline: null,
          isArrivingNextDay: +segment.arrival.date !== +segment.departure.date,
        };
      }),
      priceDetail: {
        fareIdentifier: priceDetails.fareIdentifier,
        id: priceDetails.id,
        totalFare,
        totalBaseFare,
        totalTaxesAndFees,
        fareDescription: priceDetails.fareDescription,
      },
    };

    await airiqBookingMutation.mutateAsync(newData);
  };

  const handleBook = async () => {
    createEvent({
      priceListIndex: index,
      selectedPriceItemIndex: selectedPriceItemIndex,
      type: 'BOOKED',
    });

    localStorage.setItem(BOOKING_FLOW_ID, bookingFlowId);

    if (priceList[selectedPriceItemIndex].fareSupplier === '3') {
      await handleAiriq();
    }

    const safeId = encodeURIComponent(priceList[selectedPriceItemIndex].id);
    navigate({
      // pathname: `/flights/${priceList[selectedPriceItemIndex].id}`,
      pathname: `/flights/${safeId}`,
      search: createSearchParams({
        adult: passengers.adult.toString(),
        child: passengers.child.toString(),
        infant: passengers.infant.toString(),
        type: priceList[selectedPriceItemIndex].fareSupplier,
        cours: priceList[selectedPriceItemIndex].totalFare.toString(),
        cabinClass: searchParams.get('cabinClass') || 'ECONOMY',
        isDirectFlight: searchParams.get('isDirectFlight') || 'false',
        bookingFlowId: bookingFlowId,
      }).toString(),
    });
  };

  const getSeatMessage = (seats: number) => {
    let message = '';
    if (seats > 1) {
      message = `${seats} seats left `;
    } else {
      message = `${seats} seat left `;
    }
    return message;
  };

  if (isMobile) {
    return (
      <div className='w-full mt-5 py-4 border-[2px]  border-[#EAEAEA] rounded-2xl bg-white'>
        {/* top section */}
        <div className=' mx-4 flex justify-between items-center'>
          <div className='flex gap-[6px] items-center'>
            <img
              crossOrigin='anonymous'
              className='w-5 h-5'
              src={segments[0].flight.logo}
              alt=''
            />
            <div className='text-sm leading-normal font-semibold'>
              {getFlightNames()}
            </div>
            <div className='rounded-full bg-[#C8C8C8] h-1 w-1 '></div>
            <div className='text-xs leading-normal font-medium text-black/70'>
              {getFlightNumbers()}
            </div>
          </div>
          <div>
            <button className='text-sm font-semibold ' onClick={toggleExpanded}>
              {!isExpanded ? 'View Details' : 'Hide Details'}
            </button>
          </div>
        </div>
        <div className='mx-4 mt-6 flex justify-between'>
          <div className='flex gap-3'>
            <div>
              <div className='text-sm leading-normal font-semibold'>
                {formatTime(departure.time)}
              </div>
              <div className='text-xs leading-normal font-medium text-black/70'>
                {departure.airport.city}
              </div>
            </div>
            <div className='flex flex-col justify-center items-center gap-1'>
              <div className='bg-[#ECAE0E] h-1 w-16 rounded-lg mt-1' />
              <div className='text-xs font-normal text-black/70'>
                {formatMinutesToTime(duration)}
              </div>
            </div>
            <div>
              <div className='text-sm leading-normal font-semibold'>
                {formatTime(arrival.time)}
              </div>
              <div className='text-xs leading-normal font-medium text-black/70'>
                {arrival.airport.city}
              </div>
            </div>
          </div>
          <button
            className='btn-primary rounded-[3px] px-6 text-xs leading-7 font-semibold tracking-[-0.2px] '
            onClick={handleBook}
          >
            Book
          </button>
        </div>

        {/* list of flights */}
        <div className='mt-7 py-4 flex flex-col '>
          <motion.ul className='space-y-3' {...ulAnimation}>
            <AnimatePresence initial={false}>
              {priceList.slice(0, priceListIndex).map((price, i, allPrices) => (
                <motion.li
                  key={i}
                  {...liAnimation}
                  onTap={() => setSelectedPriceItemIndex(i)}
                >
                  <div
                    className={`flex items-center relative justify-between py-5 px-3  
                                        ${selectedPriceItemIndex === i ? 'bg-secondary/10' : ''}
                                        ${
                                          price.isOwn
                                            ? 'after:content-[""] after:absolute after:right-0 after:w-1 after:h-full after:bg-[#22C55E]'
                                            : ''
                                        }
                                        `}
                  >
                    <div className='flex gap-6 items-center'>
                      <div className='flex flex-col'>
                        <div className='text-base leading-normal font-bold'>
                          {formatCurrencyWithOutDecimal(
                            price.totalFare + +subAgencyAdditionalMarkup,
                            // price.totalFare + +subAgencyAdditionalMarkup.total,
                          )}
                        </div>
                        {showNetFare && (
                          <div className='text-[10px] leading-normal font-normal'>{`Net ${formatCurrencyWithOutDecimal(
                            price.netFare,
                          )}`}</div>
                        )}
                      </div>
                      <div className='flex flex-col gap-[2px] items-start '>
                        <div className='bg-[#ECAE0E] text-[8px] font-bold px-2 py-1 rounded-md text-black '>
                          {price.fareIdentifier}
                        </div>
                        <div className='flex items-center text-xs font-semibold gap-2'>
                          <div>
                            {capitalize(
                              price.fareDescription.adult.refundableType?.toLowerCase(),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* >>>>>>>>>>>>>>>>>>>>> */}

                    <div className='flex items-center gap-[6px]'>
                      {priceList[selectedPriceItemIndex].fareDescription.adult
                        ?.seatsRemaining && (
                        <>
                          <ChairIcon />
                          <div className='text-xs font-medium '>
                            {`${price.fareDescription.adult?.seatsRemaining} left`}
                            {/* {getSeatMessage(price.fareDescription.adult?.seatsRemaining)} */}
                          </div>
                        </>
                      )}

                      <div className='flex items-center gap-2'>
                        <input
                          id={`${index}priceRadio${i}`}
                          type='radio'
                          className='form-radio'
                          checked={selectedPriceItemIndex === i}
                          onClick={() => {
                            createEvent({
                              priceListIndex: index,
                              selectedPriceItemIndex: i,
                              type: 'SELECTED',
                            });
                          }}
                          // onChange={() => {
                          //   // alert('hello you clicked');
                          //   // console.log('hello you click in console');
                          //   // createEvent({
                          //   //   priceListIndex: index,
                          //   //   selectedPriceItemIndex: priceIndex,
                          //   // });
                          //   // setSelectedPriceItemIndex(i);
                          // }}
                        />
                        {/* <div className="line-through text-sm text-black/70">₹5,800</div> */}
                        <label
                          className='leading-6 font-bold flex items-center gap-2'
                          htmlFor={`${index}priceRadio${i}`}
                        >
                          {/* {price.isOwn && (
                                                        <div className="bg-green-500 h-2 w-2 rounded-full"></div>
                                                    )} */}
                          {/* {showNetFare && (
                                                        <span className="text-[.6rem] text-slate-500">{` | NET ${formatCurrency(
                                                            price.netFare
                                                        )}`}</span>
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>

          {priceList.length > 1 && (
            <div
              // onClick={togglePriceListIndex}

              onClick={() =>
                priceListIndex !== 1
                  ? setPriceListIndex(1)
                  : setPriceListIndex(priceList.length)
              }
              className='  cursor-pointer  text-center text-[10px] font-medium mt-3 flex items-center justify-center gap-1'
            >
              {priceListIndex === 1 ? priceList.length - 1 : ''}
              {priceListIndex === 1 ? ' more fare types' : ''}
              <span className='text-sm font-semibold leading-normal'>
                {priceListIndex === 1 ? 'Show all fares' : 'Hide'}
              </span>
            </div>
          )}

          {/* {priceList.length > displayFirst && (
                        <a
                            className="text-sm font-medium text-blue-600 mt-3 cursor-pointer"
                            onClick={togglePriceListIndex}
                        >
                            {priceListIndex === displayFirst ? 'Show all fares' : 'Hide'}
                        </a>
                    )} */}
        </div>

        {/* here */}
        {/* {isExpanded && ( */}
        {true && (
          <FlightDetailModal
            onClose={toggleExpanded}
            segments={segments}
            // onSubmit={handleBook}
            selectedPriceItem={priceList[selectedPriceItemIndex]}
            show={isExpanded}
          />
        )}
        {/* {true && <FlightDetailModal />} */}
        {/* <AnimatePresence initial={false}>
                    {isExpanded && (
                        <motion.div {...collapseAnimation}>
                            <FlightDetailTab
                                segments={segments}
                                selectedPriceItem={priceList[selectedPriceItemIndex]}
                            />
                        </motion.div>
                    )}
                </AnimatePresence> */}
      </div>
    );
  } else {
    return (
      <div className='relative bg-white rounded-md mb-2 py-6 px-6 w-full'>
        <div className='grid grid-cols-12 gap-6'>
          <div className='col-span-2'>
            <div className='flex items-center  gap-2'>
              <img
                crossOrigin='anonymous'
                className='w-8 h-8'
                src={segments[0].flight.logo}
                alt=''
              />
              <div className='overflow-hidden'>
                <div className='text-base font-semibold text-ellipsis whitespace-nowrap overflow-hidden'>
                  {getFlightNames()}
                </div>
                <div className='text-xs text-black/70 mt-[2px]'>
                  {getFlightNumbers()}
                </div>
              </div>
            </div>
            <a
              className='inline-block text-sm font-medium text-blue-600 mt-4 cursor-pointer'
              onClick={toggleExpanded}
            >
              {!isExpanded ? 'Show Details' : 'Hide Details'}
            </a>
          </div>

          <div className='col-span-5'>
            <div className='flex justify-center items-center gap-6'>
              <div className='flex flex-col items-center'>
                <div className='font-semibold'>
                  {formatTime(departure.time)}
                </div>
                <div className='text-xs font-normal text-black/70'>
                  {departure.airport.code}
                </div>
              </div>
              <div className='flex flex-col items-center mt-1'>
                <div className='text-xs font-normal text-black/70'>
                  {formatMinutesToTime(duration)}
                </div>
                <div className='bg-[#ECAE0E] h-1 w-24 rounded-lg mt-1' />
                <div className='text-xs font-normal text-black/70 mt-1'>
                  {getFormattedStops(calculateStops(segments))}
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <div className='font-semibold'>{formatTime(arrival.time)}</div>
                <div className='text-xs font-normal text-black/70'>
                  {arrival.airport.code}
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3'>
            <div className='flex flex-col'>
              <motion.ul className='space-y-3' {...ulAnimation}>
                <AnimatePresence initial={false}>
                  {priceList.slice(0, priceListIndex).map((price, i) => (
                    <motion.li key={i} {...liAnimation}>
                      <div className='flex items-center gap-2'>
                        <input
                          id={`${index}priceRadio${i}`}
                          type='radio'
                          className='form-radio'
                          checked={selectedPriceItemIndex === i}
                          onChange={() => setSelectedPriceItemIndex(i)}
                          onClick={() => {
                            createEvent({
                              priceListIndex: index,
                              selectedPriceItemIndex: i,
                              type: 'SELECTED',
                            });
                          }}
                        />
                        {/* <div className="line-through text-sm text-black/70">₹5,800</div> */}
                        <label
                          className=' leading-6 font-bold flex gap-2 items-center'
                          htmlFor={`${index}priceRadio${i}`}
                        >
                          <span className='whitespace-nowrap'>
                            {' '}
                            {/* {formatCurrency(price.totalFare)} */}
                            <PriceWithHiddenSupplier
                              totalFare={
                                price.totalFare + subAgencyAdditionalMarkup
                                // subAgencyAdditionalMarkup.total
                              }
                              fareSupplier={price.fareSupplier}
                            />
                          </span>
                          {price.isOwn && (
                            <div className='bg-green-500 h-2 w-2 rounded-full'></div>
                          )}
                        </label>
                      </div>
                      {/* ?????????????????????????????????????????????????????????????????? */}
                      {showNetFare && (
                        <div className='flex flex-col gap-1 text-xs font-extrabold py-1'>
                          <div className='block  text-green-600 '>{`NET ${formatCurrency(
                            price.netFare,
                          )}`}</div>

                          {price.commissionV2 && (
                            <div className='block  text-green-600'>{`INC ${formatCurrency(
                              price.commissionNet,
                            )}`}</div>
                          )}
                        </div>
                      )}
                      {/* ?????????????????????????????????????????????????????????????????? */}
                      <div className='flex items-center text-gray-500 text-[0.65rem] font-semibold gap-2 mt-1'>
                        <div className='bg-[#ECAE0E] px-2 py-1 rounded-md text-black '>
                          {price.fareIdentifier}
                        </div>
                        {/* {price.isOwn && (
                                                    <div className="bg-green-500 h-2 w-2 rounded-full"></div>
                                                )} */}
                        <div>
                          {capitalize(
                            price.fareDescription.adult.refundableType?.toLowerCase(),
                          )}
                        </div>
                      </div>
                    </motion.li>
                  ))}
                </AnimatePresence>
              </motion.ul>

              {priceList.length > displayFirst && (
                <a
                  className='text-sm font-medium text-blue-600 mt-3 cursor-pointer'
                  onClick={togglePriceListIndex}
                >
                  {priceListIndex === displayFirst ? 'Show all fares' : 'Hide'}
                </a>
              )}

              {/* <div className="flex items-center gap-1 text-xs mt-[2px]">
                            <div className="text-black/70 whitespace-nowrap">Series Fare</div>
                            <div className="text-black/70">|</div>
                            <div className="text-primary font-semibold whitespace-nowrap">Fare Rules</div>
                        </div> */}
            </div>
          </div>

          <div className='col-span-2'>
            <div className='flex flex:row xl:flex-col items-center justify-end gap-2'>
              <div className='flex items-center gap-2'>
                <button className='btn btn-primary' onClick={handleBook}>
                  Book
                </button>
              </div>

              {priceList[selectedPriceItemIndex].fareDescription.adult
                ?.seatsRemaining && (
                <div className='font-semibold text-black/70 text-xs  '>
                  {
                    getSeatMessage(
                      priceList[selectedPriceItemIndex].fareDescription.adult
                        ?.seatsRemaining,
                    )
                    // priceList[selectedPriceItemIndex].fareDescription.adult?.seatsRemaining
                  }
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Flight Detail Tab */}
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div {...collapseAnimation}>
              <FlightDetailTab
                segments={segments}
                selectedPriceItem={priceList[selectedPriceItemIndex]}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* {index === 2 && (
                <div className="inline-flex items-center text-xs px-6 py-2 bg-[#ECAE0E33] rounded-tr-lg">
                    <span className="bg-[#ECAE0E] rounded-[50%] w-2 h-2 mr-3"></span> Special Fare brought to you by
                    Travel Agent & Vistara
                </div>
            )} */}
      </div>
    );
  }
};

export default FlightListItem;

interface IPriceWithHiddenSupplier {
  totalFare: number;
  fareSupplier: string;
}

const PriceWithHiddenSupplier: FC<IPriceWithHiddenSupplier> = (props) => {
  const { totalFare, fareSupplier } = props;
  const { ref, isCtrlShiftOHover } = useCtrlShiftOHover();

  return (
    <>
      <span ref={ref} className='whitespace-nowrap'>
        {formatCurrency(totalFare)}
      </span>
      {isCtrlShiftOHover && `<${fareSupplier}>`}
    </>
  );
};
