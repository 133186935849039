/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import Slider from 'rc-slider';
import { FC, useState, useEffect } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import { useContext } from 'react';
import FlightsContext from 'app/pages/Flights/context/flights-context';
import { IFlightFilter } from '../../types';
import { DepartureTimeEnum, FareTypeEnum, StopTypeEnum } from 'app/enums';
import { useMediaQuery } from 'react-responsive';
import { CrossIcon } from 'app/icons';

const ulAnimation = {
  variants: {
    collapsed: { opacity: 0 },
    expanded: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  },
  initial: 'collapsed',
  animate: 'expanded',
};

const liAnimation = {
  variants: {
    collapsed: { opacity: 0, height: 0 },
    expanded: { opacity: 1, height: 'auto' },
  },
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
};

const drawerAnimation = {
  variants: {
    hidden: {
      x: '100vh',
    },
    visible: {
      x: 0,
      transition: { duration: 0.3 },
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

interface SubmitOnChangeProps {}

const SubmitOnChange: FC<SubmitOnChangeProps> = () => {
  const { initialValues, values, submitForm } = useFormikContext();

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      submitForm();
    }
  }, [initialValues, submitForm, values]);

  return null;
};

interface FilterSidebarProps {
  filter: IFlightFilter;
  onFilterChange: (values: IFlightFilter) => void;
  [x: string]: any;
  show: boolean;
  closeMobileFilter: () => void;
}

const FilterSidebar: FC<FilterSidebarProps> = (props) => {
  const { show, closeMobileFilter } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    filterByAirlineList,
    filter,
    onFilterChange,
    priceRange,
    stopCountMap,
  } = props;

  const [filterByAirlineListIndex, setFilterByAirlineListIndex] =
    useState<number>(3);

  const { departureAirport, showNetFare, setShowNetFare } =
    useContext(FlightsContext);

  const toggleFilterListIndex = () => {
    if (filterByAirlineListIndex !== 3) setFilterByAirlineListIndex(3);
    else setFilterByAirlineListIndex(filterByAirlineList.length);
  };

  const SearchForm = () => {
    return (
      <Formik
        initialValues={filter}
        enableReinitialize
        onSubmit={onFilterChange}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <SubmitOnChange />
            {/* By Airline */}
            <div className='mt-8'>
              <div className=''>
                <div className='form-switch success flex items-center gap-3 text-sm font-medium '>
                  <label>Net Fare</label>
                  <input
                    // checked={row.isLiveOnNetwork}
                    checked={showNetFare}
                    type='checkbox'
                    role='switch'
                    onChange={(e) =>
                      // handleLiveOnNetworkChange(row, e.target.checked)
                      setShowNetFare(() => !showNetFare)
                    }
                    // disabled={row.isDisabledByAgency}
                  />
                </div>
              </div>

              <div className='text-base font-bold mt-8'>By Airline</div>

              <motion.ul className='mt-4 space-y-4' {...ulAnimation}>
                <AnimatePresence initial={false}>
                  {filterByAirlineList
                    .slice(0, filterByAirlineListIndex)
                    .map((row: any) => (
                      <motion.li key={row.code} {...liAnimation}>
                        <div className='flex justify-between text-sm'>
                          <div className='flex items-center'>
                            <input
                              className='form-checkbox'
                              type='checkbox'
                              id={row.code}
                              checked={values.airlines.includes(row.code)}
                              onChange={(e: any) => {
                                if (e.target.checked)
                                  setFieldValue('airlines', [
                                    ...values.airlines,
                                    row.code,
                                  ]);
                                else
                                  setFieldValue(
                                    'airlines',
                                    values.airlines.filter(
                                      (code: string) => code !== row.code,
                                    ),
                                  );
                              }}
                            />
                            <label className='ml-2' htmlFor={row.code}>
                              {row.name} ({row.count})
                            </label>
                          </div>
                          <div className='font-normal'>
                            {formatCurrency(row.minFare)}
                          </div>
                        </div>
                      </motion.li>
                    ))}
                </AnimatePresence>
              </motion.ul>

              {filterByAirlineList.length > 3 && (
                <a
                  onClick={toggleFilterListIndex}
                  className='text-primary text-sm leading-4 font-bold block mt-5 cursor-pointer'
                >
                  {filterByAirlineListIndex === 3
                    ? `+ ${filterByAirlineList.length - filterByAirlineListIndex} more`
                    : 'Hide'}
                </a>
              )}
            </div>

            {/* Stops from Srinagar */}
            <div className='mt-8'>
              <div className='text-base font-bold'>
                Stops from {departureAirport.city}
              </div>
              <ul className='mt-4'>
                <li>
                  <div className='flex items-center text-sm'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      id='nonstop'
                      value='NON_STOP'
                      checked={values.stops.includes(StopTypeEnum.NON_STOP)}
                      onChange={(e: any) => {
                        if (e.target.checked)
                          setFieldValue('stops', [
                            ...values.stops,
                            e.target.value,
                          ]);
                        else
                          setFieldValue(
                            'stops',
                            values.stops.filter(
                              (stop: string) => stop !== e.target.value,
                            ),
                          );
                      }}
                    />
                    <label className='ml-2' htmlFor='nonstop'>
                      Non-stop ({stopCountMap.NON_STOP})
                    </label>
                  </div>
                </li>
                <li>
                  <div className='flex items-center text-sm mt-4'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      id='ONE_STOP'
                      value='ONE_STOP'
                      checked={values.stops.includes(StopTypeEnum.ONE_STOP)}
                      onChange={(e: any) => {
                        if (e.target.checked)
                          setFieldValue('stops', [
                            ...values.stops,
                            e.target.value,
                          ]);
                        else
                          setFieldValue(
                            'stops',
                            values.stops.filter(
                              (stop: string) => stop !== e.target.value,
                            ),
                          );
                      }}
                    />
                    <label className='ml-2' htmlFor='onestop'>
                      1 stop ({stopCountMap.ONE_STOP})
                    </label>
                  </div>
                </li>
                <li>
                  <div className='flex items-center text-sm mt-4'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      id='multiplestop'
                      value='MULTIPLE_STOP'
                      checked={values.stops.includes(
                        StopTypeEnum.MULTIPLE_STOP,
                      )}
                      onChange={(e: any) => {
                        if (e.target.checked)
                          setFieldValue('stops', [
                            ...values.stops,
                            e.target.value,
                          ]);
                        else
                          setFieldValue(
                            'stops',
                            values.stops.filter(
                              (stop: string) => stop !== e.target.value,
                            ),
                          );
                      }}
                    />
                    <label className='ml-2' htmlFor='multiplestop'>
                      Multiple stops ({stopCountMap.MULTIPLE_STOP})
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            {/* By Fare */}
            <div className='mt-8'>
              <div className='text-base font-bold'>
                By Fare{' '}
                <a
                  className='ml-2 font-normal text-sm text-blue-500 cursor-pointer'
                  onClick={() =>
                    setFieldValue('priceRange', [
                      priceRange.min,
                      priceRange.max,
                    ])
                  }
                >
                  Reset
                </a>
              </div>

              <div className='mt-2'>
                <div className='my-4'>
                  <Slider
                    range
                    onChange={(newValues) =>
                      setFieldValue('priceRange', newValues)
                    }
                    trackStyle={{
                      backgroundColor: 'rgb(var(--primary))',
                      height: 10,
                    }}
                    handleStyle={{
                      borderColor: 'white',
                      backgroundColor: 'white',
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.25)',
                      height: 20,
                      width: 20,
                      marginTop: -5,
                      opacity: 1,
                    }}
                    railStyle={{ height: 10 }}
                    min={priceRange.min}
                    max={priceRange.max}
                    value={filter.priceRange}
                    step={100}
                    allowCross={false}
                  />
                </div>

                <div className='flex justify-between mt-4 text-sm'>
                  <span>{formatCurrency(filter.priceRange[0])}</span>
                  <span>{formatCurrency(filter.priceRange[1])}</span>
                </div>
              </div>
            </div>

            {/* Departure Time */}
            <div className='mt-8'>
              <div className='text-base font-bold'>Departure Time</div>

              <ul className='mt-4'>
                <li>
                  <div className='flex justify-between text-sm'>
                    <div className='flex items-center'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='earlyMorning'
                        value='EARLY_MORNING'
                        checked={values.departureTime.includes(
                          DepartureTimeEnum.EARLY_MORNING,
                        )}
                        onChange={(e: any) => {
                          if (e.target.checked)
                            setFieldValue('departureTime', [
                              ...values.departureTime,
                              e.target.value,
                            ]);
                          else
                            setFieldValue(
                              'departureTime',
                              values.departureTime.filter(
                                (dt: string) => dt !== e.target.value,
                              ),
                            );
                        }}
                      />
                      <label className='ml-2' htmlFor='earlyMorning'>
                        Early Morning
                      </label>
                    </div>
                    <div className='font-normal text-gray-500'>
                      Midnight - 8 am
                    </div>
                  </div>
                </li>
                <li>
                  <div className='flex justify-between text-sm mt-4'>
                    <div className='flex items-center'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='morning'
                        value='MORNING'
                        checked={values.departureTime.includes(
                          DepartureTimeEnum.MORNING,
                        )}
                        onChange={(e: any) => {
                          if (e.target.checked)
                            setFieldValue('departureTime', [
                              ...values.departureTime,
                              e.target.value,
                            ]);
                          else
                            setFieldValue(
                              'departureTime',
                              values.departureTime.filter(
                                (dt: string) => dt !== e.target.value,
                              ),
                            );
                        }}
                      />
                      <label className='ml-2' htmlFor='morning'>
                        Morning
                      </label>
                    </div>
                    <div className='font-normal text-gray-500'>8 am - Noon</div>
                  </div>
                </li>
                <li>
                  <div className='flex justify-between text-sm mt-4'>
                    <div className='flex items-center'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='afternoon'
                        value='AFTERNOON'
                        checked={values.departureTime.includes(
                          DepartureTimeEnum.AFTERNOON,
                        )}
                        onChange={(e: any) => {
                          if (e.target.checked)
                            setFieldValue('departureTime', [
                              ...values.departureTime,
                              e.target.value,
                            ]);
                          else
                            setFieldValue(
                              'departureTime',
                              values.departureTime.filter(
                                (dt: string) => dt !== e.target.value,
                              ),
                            );
                        }}
                      />
                      <label className='ml-2' htmlFor='afternoon'>
                        Afternoon
                      </label>
                    </div>
                    <div className='font-normal text-gray-500'>Noon - 4 pm</div>
                  </div>
                </li>
                <li>
                  <div className='flex justify-between text-sm mt-4'>
                    <div className='flex items-center'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='evening'
                        value='EVENING'
                        checked={values.departureTime.includes(
                          DepartureTimeEnum.EVENING,
                        )}
                        onChange={(e: any) => {
                          if (e.target.checked)
                            setFieldValue('departureTime', [
                              ...values.departureTime,
                              e.target.value,
                            ]);
                          else
                            setFieldValue(
                              'departureTime',
                              values.departureTime.filter(
                                (dt: string) => dt !== e.target.value,
                              ),
                            );
                        }}
                      />
                      <label className='ml-2' htmlFor='evening'>
                        Evening
                      </label>
                    </div>
                    <div className='font-normal text-gray-500'>4 pm - 8 pm</div>
                  </div>
                </li>
                <li>
                  <div className='flex justify-between text-sm mt-4'>
                    <div className='flex items-center'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='night'
                        value='NIGHT'
                        checked={values.departureTime.includes(
                          DepartureTimeEnum.NIGHT,
                        )}
                        onChange={(e: any) => {
                          if (e.target.checked)
                            setFieldValue('departureTime', [
                              ...values.departureTime,
                              e.target.value,
                            ]);
                          else
                            setFieldValue(
                              'departureTime',
                              values.departureTime.filter(
                                (dt: string) => dt !== e.target.value,
                              ),
                            );
                        }}
                      />
                      <label className='ml-2' htmlFor='night'>
                        Night
                      </label>
                    </div>
                    <div className='font-normal text-gray-500'>
                      8 pm - Midnight
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* Fare Type */}
            <div className='mt-8'>
              <div className='text-base font-bold'>Fare type</div>
              <ul className='mt-4'>
                <li>
                  <div className='flex items-center text-sm'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      id='series'
                      value='SERIES'
                      checked={values.fareType.includes(FareTypeEnum.SERIES)}
                      onChange={(e: any) => {
                        if (e.target.checked)
                          setFieldValue('fareType', [
                            ...values.fareType,
                            e.target.value,
                          ]);
                        else
                          setFieldValue(
                            'fareType',
                            values.fareType.filter(
                              (type: string) => type !== e.target.value,
                            ),
                          );
                      }}
                    />
                    <label className='ml-2' htmlFor='SERIES'>
                      Series Fare
                    </label>
                  </div>
                </li>
                <li>
                  <div className='flex items-center text-sm mt-4'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      id='normal'
                      value='NORMAL'
                      checked={values.fareType.includes(FareTypeEnum.NORMAL)}
                      onChange={(e: any) => {
                        if (e.target.checked)
                          setFieldValue('fareType', [
                            ...values.fareType,
                            e.target.value,
                          ]);
                        else
                          setFieldValue(
                            'fareType',
                            values.fareType.filter(
                              (type: string) => type !== e.target.value,
                            ),
                          );
                      }}
                    />
                    <label className='ml-2' htmlFor='normal'>
                      Normal Fare
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  if (isMobile)
    return (
      <>
        <AnimatePresence initial={false} mode='wait'>
          {show && (
            <motion.div
              {...drawerAnimation}
              className='fixed top-0 left-0  bottom-0 w-screen h-screen  z-50 bg-[#EEEEEE] overflow-auto '
            >
              <div className='w-full bg-white shadow-md p-4 flex gap-4 items-center sticky top-0 z-20'>
                <button
                  type='button'
                  className=' w-7 h-7 flex justify-center items-center text-gray-400 border-none cursor-pointer'
                  onClick={closeMobileFilter}
                >
                  <CrossIcon width={20} height={20} />
                </button>
                <div className='text-base font-semibold text-slate-600'>
                  Filter
                </div>
              </div>
              <div className='px-6 pb-4'>
                <SearchForm />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );

  return (
    <>
      <div className='card px-5 py-5 sticky top-[calc(var(--navbar-height)+16px)] h-[calc(100vh-var(--navbar-height)-32px)] overflow-auto no-scrollbar'>
        <div className='text-base leading-5 font-semibold'>Filter</div>
        <SearchForm />
      </div>
    </>
  );
};

export default FilterSidebar;
