// import { toast } from 'react-toastify';
import { useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  addEventToBookingFlow,
  createBookingFlow,
} from 'app/api/services/booking-flow-service.service';
import { ILoggerBookingEvent } from 'app/types/booking-event';

const useEventLogging = (fId?: string) => {
  const [bookingFlowId, setBookingFlowId] = useState<string>(fId || null);

  const createBookingFlowIdMutation = useMutation(
    (events: Array<ILoggerBookingEvent>) => createBookingFlow(events),
    {
      onSuccess: (generatedBFId) => setBookingFlowId(generatedBFId),
      onError: () => {
        // toast.error('Something went wrong')
      },
    },
  );

  const eventBookingMutation = useMutation(
    (payload: { bookingFlowId: string; payload: ILoggerBookingEvent }) =>
      addEventToBookingFlow(payload),
    {
      onError: () => {
        // toast.error('Failed to add event to booking flow')
      },
    },
  );

  const createEvent = useCallback(
    (payload: ILoggerBookingEvent) => {
      if (bookingFlowId) {
        eventBookingMutation.mutate({
          bookingFlowId,
          payload,
        });
      } else {
        // toast.error('Booking Flow ID is not set');
      }
    },
    [bookingFlowId, eventBookingMutation],
  );

  const checkWorkingFlow = (payload: { localStg: string; qryPrm: string }) => {
    console.log({ payload });

    if (payload.localStg) {
      if (payload.qryPrm) {
        if (payload.localStg === payload.qryPrm.trim()) {
          console.log('old key used');
          setBookingFlowId(payload.localStg);
        }
      }
    } else {
      createBookingFlowIdMutation.mutate([]);
    }
  };

  return {
    createEvent,
    bookingFlowId,
    generateNewBookingFlowId: createBookingFlowIdMutation.mutate,
    checkWorkingFlow,
  };
};

export default useEventLogging;
