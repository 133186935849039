export const BOOKING_FLOW_ID = 'BOOKING_FLOW_ID';

export const AMENDMENT_STATUS = Object.freeze({
  PENDING: 'PENDING',
  APPROVED_BY_SUB_AGENCY: 'APPROVED_BY_SUB_AGENCY',
  COMPLETED: 'COMPLETED', //means approved by agency
  AWAITING_SUB_AGENCY_APPROVAL: 'AWAITING_SUB_AGENCY_APPROVAL',
  AWAITING_AGENCY_APPROVAL: 'AWAITING_AGENCY_APPROVAL',
  REJECTED: 'REJECTED',
});
