import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import React, { FC, useEffect, useState } from 'react';
import styles from './AgencyRegistration.module.scss';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { hexToRgb } from 'app/utils/color-utils';
import Color from 'color';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { Link, useNavigate } from 'react-router-dom';
import { BackIcon } from 'app/icons';
import StaticNav from 'app/components/StaticNav/StaticNav';
import noAuthHttp from 'app/config/noAuthHttp';

const registrationSchema = Yup.object({
  subAgencyName: Yup.string().trim().required('This field is required'),
  subAgencyAddress: Yup.string().trim().required('This field is required'),
  subAgencyGst: Yup.string().trim(),
  subAgencyPan: Yup.string().trim().required('This field is required'),
  subAgencyEmail: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email'),
  subAgencyPhoneNumber: Yup.string()
    .trim()
    .max(10, 'Phone number can be 10 digits only')
    .min(10, 'Phone number must be 10 digits ')
    .required('This field is required')
    .matches(/^\d+$/, 'Enter a valid mobile number'),
  name: Yup.string().trim().required('This field is required'),
  email: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email'),
  designation: Yup.string().trim().required('This field is required'),
  phoneNumber: Yup.string()
    .trim()
    .max(10, 'Phone number can be 10 digits only')
    .min(10, 'Phone number must be 10 digits ')
    .required('This field is required')
    .matches(/^\d+$/, 'Enter a valid mobile number'),
  personalKyc: Yup.mixed().required('This field is required'),
  agencyRegistration: Yup.mixed().required('This field is required'),
  panCard: Yup.mixed().required('This field is required'),
  gstRegistration: Yup.mixed(),
});

const initialValues: any = {
  subAgencyName: '',
  subAgencyAddress: '',
  subAgencyGst: '',
  subAgencyPan: '',
  subAgencyEmail: '',
  subAgencyPhoneNumber: '',
  name: '',
  email: '',
  designation: '',
  phoneNumber: '',
  personalKyc: null,
  agencyRegistration: null,
  panCard: null,
  gstRegistration: null,
};

interface AgencyRegistrationProps {}

const AgencyRegistration: FC<AgencyRegistrationProps> = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    logo: '',
    tagline: '',
    staticPages: [],
  });

  const loginQuery = useQuery(
    ['loginDetails'],
    async () => {
      const { data } = await noAuthHttp.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/details`,
        {
          domain: window.location.hostname,
          // domain: 'sub-agent.farepod.co',  // example is here
        },
      );
      return data;
    },
    {
      retry: 0,
    },
  );

  // Set Data
  useEffect(() => {
    if (loginQuery.data) {
      const {
        name,
        email,
        phoneNumber,
        logoUrl,
        iconUrl,
        tagline,
        primaryColor,
        secondaryColor,
        staticPages,
      } = loginQuery.data;

      setData({
        name: name,
        logo: logoUrl,
        tagline: tagline,
        email: email,
        phoneNumber: phoneNumber,
        staticPages: staticPages,
      });

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }

      // set title
      document.title = name + ' - ' + tagline;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
  }, [loginQuery.data]);

  const requestSubagencyMutation = useMutation(async (payload: any) => {
    const { data } = await noAuthHttp.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/request-registration`,
      payload,
    );

    return data;
  });

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append('agencyId', loginQuery.data._id);
      formData.append('subAgencyName', values.subAgencyName);
      formData.append('subAgencyAddress', values.subAgencyAddress);
      formData.append('subAgencyGst', values.subAgencyGst);
      formData.append('subAgencyPan', values.subAgencyPan);
      formData.append('subAgencyEmail', values.subAgencyEmail);
      formData.append('subAgencyPhoneNumber', values.subAgencyPhoneNumber);
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('designation', values.designation);
      formData.append('phoneNumber', values.phoneNumber);
      formData.append('personalKyc', values.personalKyc);
      formData.append('agencyRegistration', values.agencyRegistration);
      formData.append('panCard', values.panCard);
      formData.append('gstRegistration', values.gstRegistration);

      await requestSubagencyMutation.mutateAsync(formData);
      toast.success('Request added successfully.');
      navigate('/', { replace: true });
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  if (loginQuery.isLoading) return <PageLoader color='#000000' />;

  if (loginQuery.isError) {
    navigate('/', { replace: true });
  }

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img
            src={data.logo}
            className='h-12'
            alt=''
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
        </div>

        <div className='flex   gap-0 md:gap-6    sm:-mr-10'>
          <div
            className={`${styles['card-shadow']} card rounded-lg  gap-3 overflow-hidden mt-10 w-full`}
          >
            <div className=' px-8 py-10   '>
              {/* Form Heading */}
              <div className='mb-5'>
                <Link
                  className='inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900'
                  to='/'
                >
                  <BackIcon height={12} />
                  <span className='ms-2'>Back</span>
                </Link>
              </div>
              <h3 className='font-bold'>Agency Registration</h3>
              <div className='text-base text-gray-800 font-light mt-3'>
                Please enter the following details to register as an agent
              </div>

              {/* Form */}
              <Formik
                initialValues={initialValues}
                validationSchema={registrationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div>
                      {/* Agency Details */}
                      <div className='mt-8'>
                        <h6 className='font-bold'>Agency Details</h6>
                        <div className='grid  sm:grid-cols-2 sm:gap-7'>
                          {/* left */}
                          <div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyName'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Agency Name
                              </label>
                              <Field
                                id='subAgencyName'
                                name='subAgencyName'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyName' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyGst'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                GST Number
                              </label>
                              <Field
                                id='subAgencyGst'
                                name='subAgencyGst'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyGst' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyEmail'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Agency Email
                              </label>
                              <Field
                                id='subAgencyEmail'
                                name='subAgencyEmail'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyEmail' />
                              </div>
                            </div>
                          </div>
                          {/* right */}
                          <div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyAddress'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Agency Address
                              </label>
                              <Field
                                id='subAgencyAddress'
                                name='subAgencyAddress'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyAddress' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyPan'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                PAN Number
                              </label>
                              <Field
                                id='subAgencyPan'
                                name='subAgencyPan'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyPan' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='subAgencyPhoneNumber'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Agency Contact
                              </label>
                              <Field
                                id='subAgencyPhoneNumber'
                                name='subAgencyPhoneNumber'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='subAgencyPhoneNumber' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Personal Details */}
                      <div className='mt-8'>
                        <h6 className='font-bold'>Personal Details</h6>
                        <div className='grid  sm:grid-cols-2 sm:gap-7'>
                          {/* left */}
                          <div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='name'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                First & Last Name
                              </label>
                              <Field
                                id='name'
                                name='name'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='name' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='email'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Email
                              </label>
                              <Field
                                id='email'
                                name='email'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='email' />
                              </div>
                            </div>
                          </div>
                          {/* right */}
                          <div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='designation'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Designation
                              </label>
                              <Field
                                id='designation'
                                name='designation'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='designation' />
                              </div>
                            </div>
                            <div className='relative mt-4'>
                              <label
                                htmlFor='phoneNumber'
                                className='absolute top-2 block px-4 text-sm text-gray-500'
                              >
                                Mobile Number
                              </label>
                              <Field
                                id='phoneNumber'
                                name='phoneNumber'
                                className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                type='text'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='phoneNumber' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Documents */}
                      <div className=' mt-6 sm:mt-12 flex  flex-col sm:flex-row  gap-4  text-center      '>
                        <div className=' rounded-lg py-4 px-3 flex flex-col items-start sm:items-center  justify-between text-[#4E4E4E]'>
                          <div>
                            <div className='text-xl font-bold'>Documents</div>
                            <div className='text-xs text-red-500 font-bold'>
                              <div>Accepted formats</div>
                              <div>JPEG, PNG, PDF</div>
                            </div>
                          </div>
                        </div>

                        <div className='flex flex-wrap gap-4  '>
                          {/* KYC */}
                          <div className={styles['file-card']}>
                            <div className='text-4 font-bold '>
                              <div className='leading-5'>
                                Personal KYC{' '}
                                <span className='text-red-500'>*</span>
                              </div>
                              <div className='text-xs font-normal mt-1   '>
                                Aadhar or Passport or Driving License{' '}
                              </div>
                            </div>

                            <div className='relative mt-4 w-full'>
                              <div className='text-4 font-medium text-[#6F0214] whitespace-nowrap overflow-hidden text-ellipsis'>
                                {values.personalKyc === null
                                  ? 'Choose File'
                                  : values.personalKyc.name}
                              </div>
                              <input
                                type='file'
                                onChange={(event) => {
                                  setFieldValue(
                                    'personalKyc',
                                    event.currentTarget.files[0],
                                  );
                                }}
                                className='absolute opacity-0 top-0 left-0 w-full h-full'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='personalKyc' />
                              </div>
                            </div>
                          </div>
                          {/* agency registration */}
                          <div className={styles['file-card']}>
                            <div className='text-4 font-bold '>
                              <div className='leading-5'>
                                Agency Registration{' '}
                                <span className='text-red-500'>*</span>
                              </div>
                              <div className='text-xs font-normal mt-1   '></div>
                            </div>

                            <div className='relative mt-4 w-full'>
                              <div className='text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis '>
                                {values.agencyRegistration === null
                                  ? 'Choose File'
                                  : values.agencyRegistration.name}
                              </div>
                              <input
                                type='file'
                                onChange={(event) => {
                                  setFieldValue(
                                    'agencyRegistration',
                                    event.currentTarget.files[0],
                                  );
                                }}
                                className='absolute opacity-0 top-0 left-0 w-full h-full'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='agencyRegistration' />
                              </div>
                            </div>
                          </div>
                          {/* pan card */}
                          <div className={styles['file-card']}>
                            <div className='text-4 font-bold '>
                              <div className='leading-5'>
                                PAN Card <span className='text-red-500'>*</span>
                              </div>
                              <div className='text-xs font-normal mt-1   '></div>
                            </div>

                            <div className='relative mt-4 cursor-pointer w-full'>
                              <div className='text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis '>
                                {values.panCard === null
                                  ? 'Choose File'
                                  : values.panCard.name}
                              </div>
                              <input
                                type='file'
                                onChange={(event) => {
                                  setFieldValue(
                                    'panCard',
                                    event.currentTarget.files[0],
                                  );
                                }}
                                className='absolute opacity-0 top-0 left-0 w-full h-full'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='panCard' />
                              </div>
                            </div>
                          </div>
                          {/* GST Registration */}
                          <div className={styles['file-card']}>
                            <div className='text-4 font-bold '>
                              <div className='leading-5'>
                                GST Registration{' '}
                                <span className='text-red-500'></span>
                              </div>
                              <div className='text-xs font-normal mt-1   '></div>
                            </div>

                            <div className='relative mt-4 cursor-pointer w-full'>
                              <div className='text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis'>
                                {values.gstRegistration === null
                                  ? 'Choose File'
                                  : values.gstRegistration.name}
                              </div>
                              <input
                                type='file'
                                onChange={(event) => {
                                  setFieldValue(
                                    'gstRegistration',
                                    event.currentTarget.files[0],
                                  );
                                }}
                                className='absolute opacity-0 top-0 left-0 w-full h-full'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='gstRegistration' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='mt-20'>
                        <div className='grid grid-cols-2 gap-7'>
                          <LoaderButton
                            type='submit'
                            className='bg-primary text-white text-sm font-semibold rounded-lg full sm:mr-12'
                            isLoading={requestSubagencyMutation.isLoading}
                          >
                            Submit
                          </LoaderButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div
            className={`${styles['vertical-text']} mt-96 text-3xl font-bold text-secondary-fg hidden sm:block`}
          >
            {data.tagline}
          </div>
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

export default AgencyRegistration;
