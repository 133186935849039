import { useMutation } from '@tanstack/react-query';
import { addEventToBookingFlowWithBookingId } from 'app/api/services/booking-flow-service.service';
import { ILoggerBookingEvent } from 'app/types/booking-event';
import { useCallback } from 'react';
// import { toast } from 'react-toastify';

export const useEventLoggingOnBooking = (bookingId: string) => {
  const eventBookingMutation = useMutation(
    (payload: { bookingFlowId: string; payload: ILoggerBookingEvent }) =>
      addEventToBookingFlowWithBookingId({
        bookingId: payload.bookingFlowId,
        payload: payload.payload,
      }),
    {
      onError: () => {
        // toast.error('Failed to add event to booking flow')
      },
    },
  );

  const createEvent = useCallback(
    (payload: ILoggerBookingEvent) => {
      eventBookingMutation.mutate({
        bookingFlowId: bookingId,
        payload,
      });
    },
    [eventBookingMutation, bookingId],
  );

  return {
    createEvent,
  };
};
