import { createContext, Dispatch, SetStateAction } from 'react';
import { IAirport } from 'app/types';
import { IPassengerCount } from '../../../types/passenger-count';
import { TSubAgencyAdditionalMarkup } from 'app/types/sub-agency-markup';

export interface IFlightsContextData {
  departureAirport: IAirport;
  arrivalAirport: IAirport;
  passengers: IPassengerCount;
  showNetFare: boolean;
  setShowNetFare: Dispatch<SetStateAction<boolean>>;
  subAgencyAdditionalMarkup: TSubAgencyAdditionalMarkup;
  bookingFlowId: string;
}

const FlightsContext = createContext<IFlightsContextData>({
  departureAirport: null,
  arrivalAirport: null,
  passengers: {
    adult: 0,
    child: 0,
    infant: 0,
  },
  showNetFare: false,
  setShowNetFare: () => {},
  subAgencyAdditionalMarkup: 0,
  bookingFlowId: '',
});

export default FlightsContext;
