/* eslint-disable jsx-a11y/anchor-is-valid */
import http from 'app/config/http';
import { IBookingForm, ISaveCustomerData, ITraveller } from './types';
import { toast } from 'react-toastify';
import { IFlightSingle } from 'app/types';
import useDialog from 'app/hooks/use-dialog';
import ErrorPage from './components/ErrorPage/ErrorPage';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import { useMutation, useQuery } from '@tanstack/react-query';
import BookingForm from './components/BookingForm/BookingForm';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ProcessHeading from 'app/components/ProcessHeading/ProcessHeading';
import FlightPageLoader from './components/FlightPageLoader/FlightPageLoader';
import PriceChangedDialog from './components/PriceChangedDialog/PriceChangedDialog';
import DuplicateBookingModal from 'app/components/DuplicateBookingModal/DuplicateBookingModal';
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
  createSearchParams,
} from 'react-router-dom';
import FlightDetailWithFareRules from './components/FlightDetailWithFareRules/FlightDetailWithFareRules';
import { PassengerEnum } from 'app/enums/passenger-enum';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import BookedPartialTicketPriceDetails from 'app/components/BookedPartialTicketPriceDetails/BookedPartialTicketPriceDetails';
import { BOOKING_FLOW_ID } from 'app/utils/constants';
import useEventLogging from 'app/hooks/use-event-logging';
import {
  createDuplicateBookingFoundMessage,
  createInitialPageMessage,
  createNewPriceMessage,
  createPassengersAddedToBookingMessage,
  createProceededWithDuplicateBookingAndAddedPassengersInBookingMessage,
} from 'app/utils/event-utils';

interface PassengerBookingProps {}

const PassengerBooking: FC<PassengerBookingProps> = (props) => {
  // const { fareId } = useParams();
  const { fareId: encodedFareId } = useParams();
  const fareId = encodeURIComponent(encodedFareId);
  const { user } = useSelector((state: TRootState) => state.auth);
  const { createEvent, checkWorkingFlow } = useEventLogging();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const priceChangedDialog = useDialog();
  const previousBookingDialog = useDialog();
  const [searchParams] = useSearchParams();

  const {
    user: { additionalMarkup },
  } = useSelector((state: TRootState) => state.auth);

  useLayoutEffect(() => {
    let currentVersion = localStorage.getItem('current-version');
    const xAppVersion = localStorage.getItem('x-app-version');
    if (
      !currentVersion ||
      currentVersion === 'undefined' ||
      +xAppVersion > +currentVersion
    ) {
      localStorage.setItem('current-version', xAppVersion);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    checkWorkingFlow({
      localStg: localStorage.getItem(BOOKING_FLOW_ID),
      qryPrm: searchParams.get('bookingFlowId'),
    });
  }, []);

  // path with params
  const path = `${pathname}${searchParams.toString()}`;

  const flightDetailQuery = useQuery<IFlightSingle>(
    ['flightDetail', fareId],
    async () => {
      const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/flights/${fareId}`,
        {
          passengers: {
            adult: searchParams.get('adult'),
            child: searchParams.get('child'),
            infant: searchParams.get('infant'),
          },
          fareSupplier: searchParams.get('type'),
        },
      );

      return data;
    },
    {
      // retry: 1,
    },
  );

  let bookingFlowId = searchParams.get('bookingFlowId');

  let calculatedAdditionalMarkup =
    additionalMarkup *
    (+searchParams.get('adult') + +searchParams.get('child'));

  const checkPreviousBookingMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/duplicate-booking`,
      payload,
    );

    return data;
  });

  const createBookingMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings`,
      payload,
    );

    return data;
  });

  const saveCustomersMutation = useMutation(
    async (payload: ISaveCustomerData[]) => {
      const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/customers`,
        payload,
      );

      return data;
    },
  );

  const flight = flightDetailQuery.data;

  useEffect(() => {
    if (flight) {
      createEvent({ message: createInitialPageMessage() });
      createEvent({ message: createNewPriceMessage(flight, additionalMarkup) });
    }
  }, [flight]);

  const generatePassengerObject = (
    end: number,
    defaultTitle = '',
    dob: boolean,
  ): ITraveller[] => {
    return Array(end)
      .fill(1)
      .map((_) => {
        let model: any = {
          title: defaultTitle,
          firstName: '',
          lastName: '',
          isInfant: dob,
          dob: '',
          isInternational:
            flight.departure.airport.countryCode !== 'IN' ||
            flight.arrival.airport.countryCode !== 'IN',
          passport: {
            expiry: '',
            nationality: 'IN',
            number: '',
            issueDate: '',
          },
          savePassengerDetails: false,
        };
        return model;
      });
  };

  const initialValues = useMemo(() => {
    // retrive session variable
    const storedValue = sessionStorage.getItem(path);

    // checing if data is present in session and de-serialize form data
    if (storedValue !== null) {
      return JSON.parse(storedValue);
    }

    if (flight) {
      return {
        adults: generatePassengerObject(
          +searchParams.get('adult'),
          'Mr',
          false,
        ),
        children: generatePassengerObject(
          +searchParams.get('child'),
          'Master',
          false,
        ), // infants: getFareType() === "Series" ? generatePassengerObject(0), 'Master') : generatePassengerObject(+searchParams.get('infant'), 'Master'),
        infants: generatePassengerObject(
          +searchParams.get('infant'),
          'Master',
          true,
        ),
        mobileNumber: user.phoneNumber || '',
        email: user.email || '',
      };
    } else {
      return {
        // isInternational: false,
        adults: [],
        children: [], // infants: getFareType() === "Series" ? generatePassengerObject(0), 'Master') : generatePassengerObject(+searchParams.get('infant'), 'Master'),
        infants: [],
        mobileNumber: '',
        email: '',
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, flight]);

  const handleSavePassengers = (
    passengers: Pick<IBookingForm, 'adults' | 'children' | 'infants'>,
  ): ISaveCustomerData[] => {
    const { adults, infants, children } = passengers;
    const customerData: ISaveCustomerData[] = [];

    adults.forEach((adult) => {
      if (adult.savePassengerDetails) {
        delete adult.isInfant;
        delete adult.isInternational;
        delete adult.savePassengerDetails;
        const data: ISaveCustomerData = {
          ...adult,
          paxType: PassengerEnum.ADULT,
        };
        customerData.push(data);
      }
    });

    children.forEach((child) => {
      if (child.savePassengerDetails) {
        delete child.isInfant;
        delete child.isInternational;
        delete child.savePassengerDetails;
        const data: ISaveCustomerData = {
          ...child,
          paxType: PassengerEnum.CHILD,
        };
        customerData.push(data);
      }
    });

    infants.forEach((infant) => {
      if (infant.savePassengerDetails) {
        delete infant.isInfant;
        delete infant.isInternational;
        delete infant.savePassengerDetails;
        const data: ISaveCustomerData = {
          ...infant,
          paxType: PassengerEnum.INFANT,
        };
        customerData.push(data);
      }
    });

    return customerData;
  };

  const sanitizedPassengers = (
    passengers: ITraveller[],
    paxType: PassengerEnum,
  ) => {
    return passengers.map((passenger) => {
      if (paxType === PassengerEnum.ADULT) {
        if (!flight.conditionalFields.dob.adult) passenger.dob = undefined;
      }

      if (paxType === PassengerEnum.CHILD) {
        if (!flight.conditionalFields.dob.child) passenger.dob = undefined;
      }

      if (paxType === PassengerEnum.INFANT) {
        if (!flight.conditionalFields.dob.infant) passenger.dob = undefined;
      }

      delete passenger.isInternational;
      // delete passenger.savePassengerDetails;
      delete passenger.isInfant;
      return passenger;
    });
  };

  const handleSubmit = async (values: IBookingForm) => {
    //form data stored in session storage
    sessionStorage.setItem(path, JSON.stringify(values));

    const payload = {
      segments: flight.segments.map((segment) => ({
        flight: segment.flight,
        duration: segment.duration,
        layoverDuration: segment.layoverDuration,
        arrival: segment.arrival,
        departure: segment.departure,
        stops: segment.stops,
        stopsOverAirports: segment.stopsOverAirports,
      })),
      priceDetail: flight.priceDetail,
      bookingId: flight.bookingId,
      duration: flight.duration,
      fareSupplier: flight.fareSupplier,
      passengers: {
        adults: sanitizedPassengers(values.adults, PassengerEnum.ADULT),
        children: sanitizedPassengers(values.children, PassengerEnum.CHILD),
        infants: sanitizedPassengers(values.infants, PassengerEnum.INFANT),
      },
      contactDetails: {
        mobileNumber: values.mobileNumber,
        email: values.email,
      },
      bookingFlowId: bookingFlowId,
    };

    saveCustomersMutation.mutate(handleSavePassengers(payload.passengers));
    try {
      const data = await checkPreviousBookingMutation.mutateAsync(payload);

      if (data.status === 200) {
        createEvent({
          message: createPassengersAddedToBookingMessage({
            adultCount: payload.passengers.adults.length,
            childCount: payload.passengers.children.length,
            infantCount: payload.passengers.infants.length,
          }),
          eventType: 'ADDED_PAX',
          meta: {
            passengers: payload.passengers,
            contactDetails: payload.contactDetails,
          },
        });
        const res = await createBookingMutation.mutateAsync(payload);
        navigate(`/payment/${res._id}`);
      } else {
        // now weather to run mutation or not will be decided in modal
        createEvent({
          message: createDuplicateBookingFoundMessage(
            {
              adultCount: payload.passengers.adults.length,
              childCount: payload.passengers.children.length,
              infantCount: payload.passengers.infants.length,
            },
            data,
          ),
          eventType: 'DUPLICATE_BOOKING',
          meta: {
            passengers: payload.passengers,
            contactDetails: payload.contactDetails,
          },
        });
        previousBookingDialog.show({
          confirm: async () => {
            createEvent({
              message:
                createProceededWithDuplicateBookingAndAddedPassengersInBookingMessage(
                  {
                    adultCount: payload.passengers.adults.length,
                    childCount: payload.passengers.children.length,
                    infantCount: payload.passengers.infants.length,
                  },
                ),
              eventType: 'ADDED_PAX',
              meta: {
                passengers: payload.passengers,
                contactDetails: payload.contactDetails,
              },
            });
            previousBookingDialog.hide();
            const res = await createBookingMutation.mutateAsync(payload);
            navigate(`/payment/${res._id}`);
          },
          data: data.booking,
        });
      }
    } catch (ex) {
      // toast.error(ex?.data?.message || 'Some error occured, please try again.');
      toast.error(
        'Something went wrong while confirming your ticket with the airline. This might be due to non-availability of the flight or the fare has changed. Please try again after sometime.',
      );
      console.log(ex);
    }
  };

  useEffect(() => {
    if (flight) {
      const viewedPrice = searchParams.get('cours');
      if (+viewedPrice !== flight.priceDetail.totalFare)
        priceChangedDialog.show();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight]);

  const navigateToSearchListPage = () => {
    navigate({
      pathname: `/flights`,
      search: createSearchParams({
        toCode: flight.arrival.airport.code,
        toName: flight.arrival.airport.name,
        toCityCode: flight.arrival.airport.cityCode,
        toCity: flight.arrival.airport.city,
        toCountry: flight.arrival.airport.country,
        toCountryCode: flight.arrival.airport.countryCode,
        fromCode: flight.departure.airport.code,
        fromName: flight.departure.airport.name,
        fromCityCode: flight.departure.airport.cityCode,
        fromCity: flight.departure.airport.city,
        fromCountry: flight.departure.airport.country,
        fromCountryCode: flight.departure.airport.countryCode,
        departureDate: flight.departure.date,
        pft: 'REGULAR',
        adult: searchParams.get('adult'),
        child: searchParams.get('child'),
        infant: searchParams.get('infant'),
        cabinClass: searchParams.get('cabinClass') || 'ECONOMY',
        isDirectFlight: searchParams.get('isDirectFlight') || 'false',
      }).toString(),
    });
  };

  if (flightDetailQuery.isLoading) return <FlightPageLoader />;

  if (
    flightDetailQuery.isError ||
    !searchParams.get('cours') ||
    isNaN(+searchParams.get('cours'))
  ) {
    return <ErrorPage />;
  }

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <ProcessHeading title='Complete your booking' activeIndex={1} />

        <div className='grid grid-cols-3 gap-9'>
          <div className='col-span-3 lg:col-span-2'>
            <FlightDetailWithFareRules flight={flight} />
            <BookingForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              isLoading={
                createBookingMutation.isLoading ||
                checkPreviousBookingMutation.isLoading
              }
              tripDate={flight.departure.date}
              conditionalField={flight.conditionalFields}
            />
          </div>
          {/* side cards */}
          <div className='col-span-3 lg:col-span-1'>
            <BookedPartialTicketPriceDetails
              flightDetails={flight}
              additionalMarkup={calculatedAdditionalMarkup}
            />
          </div>
        </div>
      </div>
      {previousBookingDialog.isOpen && (
        <DuplicateBookingModal
          onClose={previousBookingDialog.hide}
          data={previousBookingDialog.data.data}
          show={previousBookingDialog.isOpen}
          onConfirm={previousBookingDialog.data.confirm}
          createEvent={createEvent}
        />
      )}

      {priceChangedDialog.isOpen && (
        <PriceChangedDialog
          show={priceChangedDialog.isOpen}
          prevPrice={+searchParams.get('cours')}
          currentPrice={flight.priceDetail.totalFare}
          onProceed={priceChangedDialog.hide}
          onBackToSearch={navigateToSearchListPage}
          createEvent={createEvent}
        />
      )}
    </div>
  );
};

export default PassengerBooking;
