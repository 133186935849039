import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import BookingNav from 'app/pages/Bookings/components/BookingNav/BookingNav';
import { FC, useEffect, useLayoutEffect, useState } from 'react';

import BookingTable from 'app/pages/Bookings/components/BookingTable/BookingTable';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';
import { useSearchParams } from 'react-router-dom';

export enum bookingNavTabEnum {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  IN_PROCESS = 'IN_PROCESS',
  ROLLED_BACK = 'ROLLED_BACK',
}

interface BookingsProps {}

const Bookings: FC<BookingsProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [activeTab, setActiveTab] = useNavTab('1');
  const [activeTab, setActiveTab] = useState('1');

  const [bgColor, setBgColor] = useState<string>('bg-secondary');
  const [txtColor, setTxtColor] = useState<string>('text-secondary-fg');

  useEffect(() => {
    let bgColor = '';
    let txtColor = '';

    switch (activeTab) {
      case '1':
        bgColor = 'bg-secondary';
        txtColor = 'text-secondary-fg';
        break;
      case '2':
      case '5':
        bgColor = 'bg-success';
        txtColor = 'text-white';
        break;
      case '3':
        bgColor = 'bg-danger';
        txtColor = 'text-white';
        break;
      case '4':
      case '6':
        bgColor = 'bg-danger';
        txtColor = 'text-white';
        break;
    }

    setBgColor(bgColor);
    setTxtColor(txtColor);
  }, [activeTab]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set('tab', activeTab);
        prevSearchParams.set('page', '1');
        return prevSearchParams;
      });
    }, 1);
  }, [activeTab]);

  // useLayoutEffect(() => {
  //   setSearchParams((prevSearchParams) => {
  //     prevSearchParams.set('tab', activeTab);
  //     prevSearchParams.set('page', '1');
  //     return prevSearchParams;
  //   });
  // }, [activeTab]);

  // useEffect(() => {
  //   setSearchParams((prevSearchParams) => {
  //     const newSearchParams = new URLSearchParams(prevSearchParams.toString());
  //     newSearchParams.set('tab', activeTab);
  //     newSearchParams.set('page', '1');
  //     return newSearchParams;
  //   });
  // }, [activeTab]);

  return (
    <div>
      <MobileTopBar />
      <BgOverlay bgClass={bgColor} />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className={`title ${txtColor}`}>My Bookings</div>
        </div>

        <div className='card py-9'>
          <BookingNav activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className='mt-10 overflow-auto'>
            {activeTab === '1' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.COMPLETED}
                key={bookingNavTabEnum.COMPLETED}
              />
            )}

            {activeTab === '2' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.PENDING}
                key={bookingNavTabEnum.PENDING}
              />
            )}
            {activeTab === '3' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.CANCELLED}
                key={bookingNavTabEnum.CANCELLED}
              />
            )}
            {activeTab === '4' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.FAILED}
                key={bookingNavTabEnum.FAILED}
              />
            )}
            {activeTab === '5' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.IN_PROCESS}
                key={bookingNavTabEnum.IN_PROCESS}
              />
            )}
            {activeTab === '6' && (
              <BookingTable
                bookingNavTab={bookingNavTabEnum.ROLLED_BACK}
                key={bookingNavTabEnum.ROLLED_BACK}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
