import { FC, useState } from 'react';
import { TRootState } from 'app/store';
import { INavLink } from '../NavBarTwo';
import { ChevronIcon } from 'app/icons';
import styles from './../Navbar.module.scss';
import { authActions } from 'app/store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import HamburgerMenu from 'app/components/HamburgerMenu/HamburgerMenu';
import { createSearchParams, NavLink, useNavigate } from 'react-router-dom';

const collapseAnimation = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' },
};

interface ITabNavbarProps {
  navItems: INavLink[];
}

const TabNavbar: FC<ITabNavbarProps> = (props) => {
  const { navItems } = props;
  const [showNavItems, setShowNavItems] = useState(false);
  // const { navItems, isLoading } = useNavLink();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: TRootState) => state.auth);

  return (
    <>
      {showNavItems && (
        <div
          className='fixed h-screen w-screen bg-black/50 z-30'
          onClick={() => setShowNavItems(false)}
        />
      )}
      <nav
        className={`fixed w-screen bg-white z-50 flex items-center px-8 h-[var(--navbar-height)] ${styles['shadow']}`}
      >
        <NavLink to={'/'}>
          <img src={user.agency.logoUrl} className='h-7' alt='' />
        </NavLink>
        <div className='ml-auto'>
          <HamburgerMenu
            isOpen={showNavItems}
            toggleOpen={() => setShowNavItems((val) => !val)}
          />
        </div>

        <div
          className={[
            'fixed top-[var(--navbar-height)] left-0 w-full bg-white border-b border-white/10 z-[10]',
            styles['nav-sidemenu'],
            showNavItems ? styles['open'] : '',
          ].join(' ')}
        >
          {showNavItems && (
            <div className='container'>
              <div className='flex flex-col items-start gap-10 pt-8 pb-10'>
                {navItems.map((navItem) => (
                  <RenderMobileLink
                    navItem={navItem}
                    action={() => setShowNavItems(false)}
                  />
                  // <div key={navItem.label}>
                  //   <NavLink
                  //     onClick={(e) => {
                  //       e.preventDefault();
                  //       setShowNavItems(false);
                  //       navigate(navItem.to);
                  //     }}
                  //     to={navItem.to}
                  //     className={({ isActive }) =>
                  //       `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
                  //     }
                  //   >
                  //     {navItem.label}
                  //   </NavLink>
                  // </div>
                ))}

                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNavItems(false);
                    navigate({
                      pathname: '/my-agency/account',
                      search: createSearchParams({
                        showDialog: 'true',
                      }).toString(),
                    });
                  }}
                  to='/my-agency/account'
                  className={({ isActive }) =>
                    `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
                  }
                >
                  Make Payment
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNavItems(false);
                    navigate('/profile');
                  }}
                  to='/profile'
                  className={({ isActive }) =>
                    `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
                  }
                >
                  Settings
                </NavLink>

                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNavItems(false);
                    dispatch(authActions.logout());
                  }}
                  to=''
                  className={({ isActive }) =>
                    `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
                  }
                >
                  <span className='pr-28  text-red-500 '>Logout</span>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

interface INavLinkItem {
  navItem: INavLink;
  action?: () => void;
}

const RenderMobileLink: FC<INavLinkItem> = (props) => {
  const { navItem, action } = props;
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  if (!navItem.children || navItem.children.length === 0)
    return (
      <div key={navItem.label} className='w-full items-center'>
        <NavLink
          onClick={(e) => {
            e.preventDefault();
            action();
            navigate(navItem.to);
          }}
          to={navItem.to}
          className={({ isActive }) =>
            `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
          }
        >
          {navItem.label}
        </NavLink>
      </div>
    );

  return (
    <div key={navItem.label} className='w-full items-center'>
      <NavLink
        onClick={(e) => {
          e.preventDefault();
          setIsSubMenuOpen((prevState) => !prevState);
        }}
        to={navItem.to}
        className={({ isActive }) =>
          `text-sm flex justify-between bg-white   ${isActive ? 'font-semibold' : 'font-normal'} `
        }
      >
        {navItem.label}{' '}
        <motion.span
          animate={{
            rotate: isSubMenuOpen ? 180 : 0,
          }}
          transition={{
            duration: 0.25,
          }}
        >
          <ChevronIcon />
        </motion.span>
      </NavLink>

      <AnimatePresence initial={true}>
        {isSubMenuOpen && (
          <motion.div
            className='flex flex-col items-start pl-4 gap-10 pt-8 pb-10 first:pt-0 last:pb-0'
            {...collapseAnimation}
          >
            {navItem.children.map((navItem) => (
              <RenderMobileLink
                navItem={navItem}
                action={action}
                key={navItem.label}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

RenderMobileLink.defaultProps = {
  action: () => {},
};

export default TabNavbar;
