import http from 'app/config/http';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PaymentGatewayPluginEnum } from 'app/enums/plugin.enum';
import { IGateWay } from 'app/components/OnlinePaymentGatewayAndMethodSelectionDialog/types/gateway-with-charges';
import OnlinePaymentGatewayAndMethodSelectionDialog from 'app/components/OnlinePaymentGatewayAndMethodSelectionDialog/OnlinePaymentGatewayAndMethodSelectionDialog';
import { ILoggerBookingEvent } from 'app/types/booking-event';
import { createPaymentModeAndIdentifierMessage } from 'app/utils/event-utils';

type ITransactionUrl = Record<PaymentGatewayPluginEnum, string>;

const useOnlinePayment = (paymentDetails: {
  url: ITransactionUrl;
  handleOuster: () => void;
  createEvent?: (payload: ILoggerBookingEvent) => void;
}) => {
  const [html, setHtml] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [onlinePaymentAvailable, setOnlinePaymentAvailable] = useState(false);

  const navigate = useNavigate();

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<IGateWay>(null);

  const [baseAmount, setBaseAmount] = useState<number>(null);
  const [body, setBody] = useState({});

  const paymentGatewayAvailabilityQuery = useQuery(
    ['payment PAYMENT_GATEWAY_AVAILABILITY'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/payment-gateways/availability`,
      );
      return data as {
        availablePaymentGateways: string[];
        isAvailable: boolean;
      };
    },
  );

  useEffect(() => {
    if (paymentGatewayAvailabilityQuery.data) {
      setOnlinePaymentAvailable(
        paymentGatewayAvailabilityQuery.data.isAvailable,
      );
    }
  }, [
    paymentGatewayAvailabilityQuery.isLoading,
    paymentGatewayAvailabilityQuery.data,
  ]);

  useEffect(() => {
    if (visibility) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const ccAvenuePaymentMutation = useMutation(async (token: string) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}${paymentDetails.url.CCAVENUE_PAYMENT}`,
      body,
      {
        params: {
          width: window.screen.width,
          height: window.screen.height,
          token: token,
        },
      },
    );
    return data;
  });

  const hdfcPaymentMutation = useMutation(async (token: string) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}${paymentDetails.url.HDFC_GATEWAY_PAYMENT}`,
      body,
      {
        params: {
          width: window.screen.width,
          height: window.screen.height,
          token: token,
        },
      },
    );
    return data;
  });

  const onSelection = async (selectedGateway: IGateWay) => {

    if (paymentDetails?.createEvent) {
      paymentDetails.createEvent({
        message: createPaymentModeAndIdentifierMessage({
          identifier: selectedGateway?.identifier,
          paymentMode: selectedGateway?.paymentMode,
        }),
      });
    }
    if (
      selectedGateway.identifier === PaymentGatewayPluginEnum.CCAVENUE_PAYMENT
    ) {
      setSelectedPaymentMethod(selectedGateway);
      setVisibility(false);

      const data = await ccAvenuePaymentMutation.mutateAsync(
        selectedGateway.token,
      );
      setHtml(data);
      setShowDialog(true);
      paymentDetails.handleOuster();
    }
    if (
      selectedGateway.identifier ===
      PaymentGatewayPluginEnum.HDFC_GATEWAY_PAYMENT
    ) {
      const data = await hdfcPaymentMutation.mutateAsync(selectedGateway.token);
      navigate('/redirecting', {
        state: {
          externalUrl: data.paymentLink,
        },
      });
    }
  };

  const show = (baseAmount: number, payload: any = {}) => {
    setBaseAmount(baseAmount);
    setVisibility(true);
    setBody(payload);
  };

  const hide = () => {
    setVisibility(false);
  };

  const handleClose = () => {
    setVisibility(false);
  };

  const UiComponent = () => {
    if (
      visibility &&
      !selectedPaymentMethod &&
      selectedPaymentMethod?.identifier !==
        PaymentGatewayPluginEnum.HDFC_GATEWAY_PAYMENT
    )
      return (
        <OnlinePaymentGatewayAndMethodSelectionDialog
          baseAmount={baseAmount}
          onClose={handleClose}
          show
          onSelection={onSelection}
          isLoading={
            ccAvenuePaymentMutation.isLoading || hdfcPaymentMutation.isLoading
          }
        />
      );

    if (selectedPaymentMethod || true)
      return (
        <>
          {showDialog && (
            <div className='bg-gray-100 w-screen h-screen fixed top-0 bottom-0 z-[999]'>
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className='mt-4'
              />
              {html === '' && (
                <div className='flex justify-center items-center h-screen w-screen'>
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </>
      );
  };

  return {
    show,
    hide,
    UiComponent,
    onlinePaymentAvailable,
  };
};

export default useOnlinePayment;
