import http from 'app/config/http';
import { ILoggerBookingEvent } from 'app/types/booking-event';

export const createBookingFlow = async (
  events: Array<ILoggerBookingEvent> = [],
) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/booking-flows`,
    {
      events,
    },
  );
  return data.bookingFlow._id as string;
};

export const addEventToBookingFlow = async (payload: {
  bookingFlowId: string;
  payload: ILoggerBookingEvent;
}) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/booking-flows/${payload.bookingFlowId}/events`,
    {
      ...payload.payload,
    },
  );
  return data;
};

export const addEventToBookingFlowWithBookingId = async (payload: {
  bookingId: string;
  payload: ILoggerBookingEvent;
}) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/booking-flows/booking/${payload.bookingId}/events`,
    {
      ...payload.payload,
    },
  );
  return data;
};
