import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import { Dropdown, DropdownList } from 'app/components/Dropdown/Dropdown';
import { SwapIcon } from 'app/icons';
import { DatePicker } from 'app/library/datepicker';
import { IFlightSearchForm } from 'app/types';
import { generateNumberArray } from 'app/utils/list-utils';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FC, useMemo } from 'react';
// import DatePicker from 'react-datepicker';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const cabinClass = [
  {
    key: 'Economy',
    value: 'ECONOMY',
  },
  {
    key: 'Premium Economy',
    value: 'PREMIUM_ECONOMY',
  },
  {
    key: 'Business',
    value: 'BUSINESS',
  },
  {
    key: 'First',
    value: 'FIRST',
  },
];

const SearchFormSchema = Yup.object({
  passengers: Yup.object({
    adult: Yup.number().min(1).max(9),
    child: Yup.number().min(0).max(8),
    infant: Yup.number().min(0).max(8),
  }),
  from: Yup.object().nullable().required('This field is required'),
  to: Yup.object()
    .nullable()
    .required('This field is required')
    .test('not same', 'Please select different to and from', function (to) {
      const { from } = this.parent;

      if (to === null || from === null) {
        return false;
      }

      return from.code !== to.code;
    }),
  travelDate: Yup.date().required('This field is required'),
});

interface FlightSearchFormProps {
  search: any;
}

const FlightSearchForm: FC<FlightSearchFormProps> = (props) => {
  const { search } = props;

  const navigate = useNavigate();

  const getTripType = () => {
    return 'One Way';
    // if (search.routeInfo.length === 1) return 'One Way';
    // else if (search.routeInfo.length === 2) return 'Round';
  };

  const initialValues: IFlightSearchForm = useMemo(() => {
    if (!search)
      return {
        passengers: {
          adult: 0,
          child: 0,
          infant: 0,
        },
        from: {
          code: '',
          name: '',
          cityCode: '',
          city: '',
          country: '',
          countryCode: '',
        },
        to: {
          code: '',
          name: '',
          cityCode: '',
          city: '',
          country: '',
          countryCode: '',
        },
        travelDate: moment().toDate(),
        isDirectFlight: false,
        cabinClass: '',
      };

    return {
      passengers: {
        adult: +search.adult,
        child: +search.child,
        infant: +search.infant,
      },
      from: {
        code: search.fromCode,
        name: search.fromName,
        cityCode: search.fromCityCode,
        city: search.fromCity,
        country: search.fromCountry,
        countryCode: search.fromCountryCode,
      },
      to: {
        code: search.toCode,
        name: search.toName,
        cityCode: search.toCityCode,
        city: search.toCity,
        country: search.toCountry,
        countryCode: search.toCountryCode,
      },
      travelDate: moment(search.departureDate, 'YYYYMMDD').toDate(),
      isDirectFlight: search.isDirectFlight === 'true' ? true : false,
      cabinClass: search.cabinClass,
    };
  }, [search]);

  const handleSwapAirport = (
    values: IFlightSearchForm,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const fromAirport = values.from;
    const toAirport = values.to;

    setFieldValue('from', toAirport, false);
    setFieldValue('to', fromAirport, false);
  };

  const handleFlightSubmit = (values: IFlightSearchForm) => {
    const totalPassenger = +values.passengers.adult + +values.passengers.child;

    if (+values.passengers.infant > +values.passengers.adult) {
      toast.error('Number of Infants cannot be greater than the Adults');
      return;
    }
    if (totalPassenger > 9) {
      toast.error('Max passengers limit is 9 (Adult + Child)');
      return;
    }

    const { adult, child, infant } = values.passengers;

    const { from, to, travelDate, isDirectFlight, cabinClass } = values;

    const departureDate = moment(travelDate).format('YYYYMMDD');

    navigate(
      {
        pathname: '/flights',
        search: createSearchParams({
          cabinClass: cabinClass,
          fromCode: from.code,
          fromName: from.name,
          fromCityCode: from.cityCode,
          fromCity: from.city,
          fromCountry: from.country,
          fromCountryCode: from.countryCode,
          toCode: to.code,
          toName: to.name,
          toCityCode: to.cityCode,
          toCity: to.city,
          toCountry: to.country,
          toCountryCode: to.countryCode,
          adult: adult.toString(),
          child: child.toString(),
          infant: infant.toString(),
          departureDate: departureDate,
          pft: 'REGULAR',
          isDirectFlight: isDirectFlight.toString(),
          // isConnectingFlight: 'false',
          timestamp: moment().format(),
        }).toString(),
      },
      { replace: true },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={SearchFormSchema}
      onSubmit={handleFlightSubmit}
    >
      {({ values, setFieldValue }: FormikProps<IFlightSearchForm>) => (
        <Form>
          <>
            <div className='grid md:grid-cols-6 xl:grid-cols-8 gap-5'>
              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <div className='bg-white/20 px-3 py-2 rounded'>
                  <label className='block text-secondary-fg/50 text-xs font-medium tracking-wider'>
                    TRIP TYPE
                  </label>
                  <div className='text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden'>
                    {getTripType()}
                  </div>
                </div>
              </div>

              <div className='col-span-7 md:col-span-4 xl:col-span-2'>
                <div className='flex gap-5 relative'>
                  <div className='flex-1'>
                    <div className='bg-white/20 rounded'>
                      <label
                        htmlFor='from'
                        className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                      >
                        FROM
                      </label>
                      <AirportSearchField
                        id='from'
                        className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                        placeholder='Select From Airport'
                        selectedAirport={values.from}
                        onSelectedAirportChange={(airport: any) =>
                          setFieldValue('from', airport)
                        }
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='from' />
                    </div>
                  </div>
                  <div className='absolute top-[10px] left-[50%] -translate-x-[50%] z-[9]'>
                    <div
                      className='bg-white/20 text-primary w-10 h-10 rounded-full flex justify-center items-center mx-auto cursor-pointer border-2 border-secondary'
                      onClick={() => handleSwapAirport(values, setFieldValue)}
                    >
                      <SwapIcon height={16} />
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='bg-white/20 rounded'>
                      <label
                        htmlFor='to'
                        className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                      >
                        TO
                      </label>
                      <AirportSearchField
                        id='to'
                        className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                        placeholder='Select To Airport'
                        selectedAirport={values.to}
                        onSelectedAirportChange={(airport: any) =>
                          setFieldValue('to', airport)
                        }
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='to' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <div className='bg-white/20 rounded'>
                  <label
                    htmlFor='travelDate'
                    className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                  >
                    DEPART
                  </label>
                  <DatePicker
                    // className="form-field bg-white pl-12 cursor-pointer"
                    className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer  outline-none'
                    value={values.travelDate}
                    onChange={(val) => setFieldValue('travelDate', val)}
                    minDate={new Date()}
                    format='DD MM YYYY'
                  />
                  {/* ??? */}
                  {/* <DatePicker
                                        id="travelDate"
                                        className="w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer caret-transparent outline-none"
                                        selected={values.travelDate}
                                        onChange={(val) => setFieldValue('travelDate', val)}
                                        minDate={new Date()}
                                        dateFormat="dd MMM, yyyy"
                                        closeOnScroll
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onChangeRaw={(e: any) => e.preventDefault()}
                                    /> */}
                </div>
              </div>

              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <div className='bg-white/20 rounded'>
                  <label className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'>
                    RETURN
                  </label>
                  <div className='text-secondary-fg text-base leading-6 font-semibold mt-1 opacity-50 whitespace-nowrap overflow-hidden text-ellipsis px-3 pb-2'>
                    Select Return
                  </div>
                </div>
              </div>

              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <div className='bg-white/20 rounded'>
                  <Dropdown
                    target={
                      <>
                        <label className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'>
                          PASSENGERS
                        </label>
                        <div className='text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden px-3 pb-2'>
                          {values.passengers.adult} Adult,{' '}
                          {values.passengers.child} Children,{' '}
                          {values.passengers.infant} Infant
                        </div>
                      </>
                    }
                    menu={
                      <DropdownList>
                        <div className='flex px-8 py-2 gap-x-5'>
                          <div className='w-full'>
                            <label
                              htmlFor='adult'
                              className='form-label text-secondary-fg'
                            >
                              Adult
                            </label>
                            <div className='w-full'>
                              <Field
                                id='adult'
                                as='select'
                                name='passengers.adult'
                                className='form-field'
                              >
                                {generateNumberArray(1, 9).map((val) => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  );
                                })}
                              </Field>
                            </div>
                          </div>
                          <div className='w-full'>
                            <div className='relative'>
                              <label
                                htmlFor='children'
                                className='form-label text-secondary-fg'
                              >
                                Children
                              </label>
                              <Field
                                id='children'
                                as='select'
                                name='passengers.child'
                                className='form-field'
                              >
                                {generateNumberArray(0, 8).map((val) => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  );
                                })}
                              </Field>

                              <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em] left-[1em]'>
                                2 - 11 Years
                              </div>
                            </div>
                          </div>
                          <div className='w-full'>
                            <div className='relative'>
                              <label
                                htmlFor='infants'
                                className='form-label text-secondary-fg'
                              >
                                Infants
                              </label>

                              <Field
                                id='infants'
                                as='select'
                                name='passengers.infant'
                                className='form-field'
                              >
                                {generateNumberArray(0, 9).map((val) => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  );
                                })}
                              </Field>

                              <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em]'>
                                Below 2 Years
                              </div>
                            </div>
                          </div>
                        </div>
                      </DropdownList>
                    }
                  />
                </div>
                <div className='form-error text-secondary-fg/60'>
                  <ErrorMessage name='passengers.adult' />
                </div>
                <div className='form-error text-secondary-fg/60'>
                  <ErrorMessage name='passengers.child' />
                </div>
                <div className='form-error text-secondary-fg/60'>
                  <ErrorMessage name='passengers.infant' />
                </div>
              </div>
              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <div className='bg-white/20 rounded pr-4'>
                  <label
                    htmlFor='from'
                    className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                  >
                    Cabin
                  </label>
                  <Field
                    as='select'
                    name='cabinClass'
                    type='text'
                    id='cabin-class'
                    className='w-full bg-transparent px-2  pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                  >
                    <option value='' disabled>
                      Select Cabin
                    </option>
                    {cabinClass.map((row: any) => (
                      <option
                        className='text-black'
                        key={row.value}
                        value={row.value}
                      >
                        {`${row.key}`}
                      </option>
                    ))}
                  </Field>
                  <div className='form-error'>
                    <ErrorMessage name='cabinClass' />
                  </div>
                </div>
              </div>
              <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                <button
                  type='submit'
                  className='btn btn-primary min-h-[58px] w-full py-3 text-base'
                >
                  Modify Search
                </button>
              </div>
              {/* <div className="col-span-7 md:col-span-2 xl:col-span-1">
                                <div className="flex sm:justify-center items-center mt-5">
                                    <div className="form-switch success">
                                        <input
                                            id="isDirectFlight"
                                            type="checkbox"
                                            role="switch"
                                            checked={values.isDirectFlight}
                                            onChange={() => {}}
                                            onClick={() => setFieldValue('isDirectFlight', !values.isDirectFlight)}
                                        />
                                    </div>
                                    <label htmlFor="isDirectFlight" className="form-label text-secondary-fg mb-0 ml-3">
                                        Direct Flights
                                    </label>
                                </div>
                            </div> */}
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default FlightSearchForm;
