export enum BookingStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  IN_PROCESS = 'IN_PROCESS',
  SUBMISSION_PENDING = 'SUBMISSION_PENDING',
  SUBMITTED = 'SUBMITTED',
  AWAITING_PNR = 'AWAITING_PNR',
  SUB_AGENT_ABORT = 'SUB_AGENT_ABORT',
  AGENT_ABORT = 'AGENT_ABORT',
  FAILED = 'FAILED',
  ROLLED_BACK = 'ROLLED_BACK', // means booking was rolled back after it had been AWAITING_PNR or IN_PROCESS
}
