import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { EditPencilIcon } from 'app/icons';
import { getInitials } from 'app/utils/common-utils';
import EditAgencyForm from '../EditAgencyForm/EditAgencyForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SubAgencyDocuments from '../SubAgencyDocuments/SubAgencyDocuments';
import SubAgencyMarkup from '../SubAgencyMarkup/SubAgencyMarkup';
import { produce } from 'immer';
import { toggleSubAgencyShowNetFareDefault } from 'app/api/services/sub-agency.service';

interface EditAgencyProps {
  profile: any;
}

const EditAgency: FC<EditAgencyProps> = (props) => {
  const { profile } = props;
  const queryClient = useQueryClient();

  const changeLogoMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/logo`,
      payload,
    );

    return data;
  });

  const handleLogoChange = async (files: File[]) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);

      try {
        const { logoUrl } = await changeLogoMutation.mutateAsync(formData);
        queryClient.setQueryData(['subAgency'], (prev: any) => {
          return update(prev, {
            logoUrl: {
              $set: logoUrl,
            },
          });
        });
      } catch (err: any) {
        const errorMsg = err.data?.message
          ? err.data.message
          : 'Some error occured while updating the image, please try again.';
        toast.error(errorMsg);
      }
    }
  };

  const toggleSubAgencyShowNetFareDefaultMutation = useMutation(
    (value: boolean) => toggleSubAgencyShowNetFareDefault(value),
  );

  const handleToggleShowNetFareByDefault = async (
    // agencyData: any,
    isChecked: boolean,
  ) => {
    queryClient.setQueryData(['subAgency'], (prev: any) => {
      return produce(prev, (draft: any) => {
        draft.showNetFareByDefault = isChecked;
      });
    });

    try {
      await toggleSubAgencyShowNetFareDefaultMutation.mutateAsync(isChecked);
      toast.success(`Show Net Fare by default changed successfully`);
      queryClient.fetchQuery(['profileData']);
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      queryClient.setQueryData(['subAgency'], (prev: any) => {
        return produce(prev, (draft: any) => {
          draft.showNetFareByDefault = !isChecked;
        });
      });
    }
  };

  return (
    <div className='mt-10'>
      <div className='flex items-center gap-4 mb-10'>
        {profile.logoUrl && !profile.logoUrl?.endsWith('/sub-agency-logos/') ? (
          <>
            <img src={profile.logoUrl} className='h-8' alt='' />
          </>
        ) : (
          <div className='flex flex-col gap-2 '>
            <div className='w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center'>
              <div className='text-primary text-lg font-semibold'>
                {getInitials(profile.name)}
              </div>
            </div>
          </div>
        )}

        <div className='relative'>
          <EditPencilIcon />
          <input
            type='file'
            id='logo'
            className='absolute top-0 left-0 w-full h-full opacity-0'
            onChange={(e: any) => handleLogoChange(e.target.files)}
          />
        </div>
      </div>

      <EditAgencyForm initialValues={profile} />

      <div className='mt-6'>
        <div className='form-switch success flex items-center gap-4 '>
          <input
            checked={profile.showNetFareByDefault}
            type='checkbox'
            role='switch'
            onChange={(e) => handleToggleShowNetFareByDefault(e.target.checked)}
          />
          <label className='text-sm  block' htmlFor='name'>
            Show Net Fare by default
          </label>
        </div>
      </div>

      <SubAgencyDocuments />

      <SubAgencyMarkup />
    </div>
  );
};

export default EditAgency;
