import axios from 'axios';
import store from 'app/store';
import { authActions } from 'app/store/auth';

// axios instance for making requests
const http = axios.create({
  timeout: 150000,
});

// request interceptor for adding token
http.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  config.headers['aystav'] = 'awhsa';

  if (token) {
    // Add token to request headers
    config.headers['authorization'] = `Bearer ${token}`;
  }

  return config;
});

http.interceptors.response.use(
  (response) => {
    localStorage.setItem('x-app-version', response.headers?.['x-app-version']);
    return response;
  },
  (error) => {
    console.log({ error });
    if (error.response.status === 401) {
      if (process.env.NODE_ENV === 'development') {
        console.log({ error });
        alert('Check console ');
      }

      store.dispatch(authActions.logout());
      window.location.href = `/login`;
    }

    return Promise.reject(error.response);
  },
);

export default http;
