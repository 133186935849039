import useNavLink from './useNavQuery';
import TabNavbar from './TabNavbar/TabNavbar';
import DestopNavBar from './DesktopNavBar/DestopNavBar';


import { useMediaQuery } from 'react-responsive';

export interface INavLink {
  to: string;
  label: string;
  children: INavLink[];
}

const NavBarTwo = () => {
  const { navItems } = useNavLink();
  const isTab = useMediaQuery({ maxWidth: 1024 });

  return (
    <>
      {isTab ? (
        <TabNavbar navItems={navItems} />
      ) : (
        <DestopNavBar navItems={navItems} />
      )}
    </>
  );
};

export default NavBarTwo;
