import * as Yup from 'yup';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setAgencyMarkUp } from 'app/api/services/sub-agency.service';
import { toast } from 'react-toastify';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const validationSchema = Yup.object({
  additionalMarkup: Yup.number()
    .typeError('You must specify a number')
    .required('This field is required')
    .min(0, 'The amount must be greater than Zero'),
});

const SubAgencyMarkup = () => {
  const queryClient = useQueryClient();

  const {
    user: { additionalMarkup },
  } = useSelector((state: TRootState) => state.auth);

  const initialValues = {
    additionalMarkup,
  };

  const agencyMarkUpMutation = useMutation(async (additionalMarkup: number) =>
    setAgencyMarkUp(additionalMarkup),
  );

  const handleSubmit = async (values: { additionalMarkup: number }) => {
    try {
      await agencyMarkUpMutation.mutateAsync(values.additionalMarkup);
      queryClient.fetchQuery(['profileData']);
      toast.success('Agency Markup updated successfully');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    } finally {
    }
  };

  return (
    <div className='mt-8'>
      {/* <div className='my-8 border-primary/30 border-t-[1px] w-full px-6' /> */}

      <div className='text-base font-bold'>Markup</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className='flex flex-col md:flex-row  mt-5 gap-4'>
              <div className='grow-[3]'>
                <label
                  className='text-xs mb-2 block'
                  htmlFor='additionalMarkup'
                >
                  Markup (per seat)
                </label>
                <Field
                  name='additionalMarkup'
                  type='text'
                  id='additionalMarkup'
                  className='form-control  py-[10px] '
                />
              </div>

              <div className='flex-grow-[1]  mt-auto h-full'>
                <LoaderButton
                  className='
                bg-primary
                  text-white
                 text-sm
                 font-semibold
                 rounded-lg
                 w-full'
                >
                  Update Markup
                </LoaderButton>
              </div>
            </div>
            <div className='form-error'>
              <ErrorMessage name='additionalMarkup' />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubAgencyMarkup;
