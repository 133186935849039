import http from 'app/config/http';

export const createAmendment = async (payload: {
  bookingId: string;
  amendment: {
    message: string;
    type: string;
  };
}) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${payload.bookingId}/amendments`,
    {
      ...payload.amendment,
    },
  );
  return data;
};
