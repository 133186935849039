import http from 'app/config/http';

export const setAgencyMarkUp = async (additionalMarkup: number) => {
  const { data } = await http.put(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/additional-markup`,
    { additionalMarkup },
  );
  return data;
};

export const toggleSubAgencyShowNetFareDefault = async (
  showNetFareByDefault: boolean,
) => {
  const { data } = await http.patch(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/net-fare-flag`,
    { showNetFareByDefault },
  );
  return data;
};
