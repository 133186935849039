import { PaymentTypeEnum } from './../pages/PaymentRef/PaymentRef';
import { store } from 'app/store';
import { IFlightSingle } from 'app/types';
import { formatCurrency } from './currency-utils';

const user = store.getState().auth.user;
const nameOfUser = user?.name || 'User';

// ****************************************************  Booking Events  ****************************************************

export const createInitialPageMessage = (): string => {
  let message = `${user || 'User'} navigated to Add Passenger Details page.`;
  return message;
};

export const createNewPriceMessage = (
  flight: IFlightSingle,
  additionalMarkup: number,
): string => {
  let message = `Fare validated again at ${formatCurrency(flight.priceDetail.totalFare + additionalMarkup)} | Original Fare ${formatCurrency(flight.priceDetail.totalFare)} |  Addition Markup ${formatCurrency(additionalMarkup)} `;
  return message;
};

export const createPriceDifferenceMessage = (
  prevPrice: number,
  currentPrice: number,
) => {
  return `The price of the ticket has change from ${formatCurrency(prevPrice)} to  ${formatCurrency(currentPrice)}  |  difference of ${formatCurrency(prevPrice - currentPrice)}`;
};

export const createPriceChangeAcceptedMessage = (currentPrice: number) => {
  let message = `${nameOfUser} has accept to proceed with current price ${currentPrice} `;
  return message;
};

export const createPriceChangeRejectedMessage = (currentPrice: number) => {
  let message = `${nameOfUser} has rejected current price ${currentPrice} and choose to go back to search the results page`;
  return message;
};

export const createPassengersAddedToBookingMessage = (values: {
  adultCount: number;
  childCount: number;
  infantCount: number;
}): string => {
  let message = `${nameOfUser} Added passenger to the booking  - ${values.adultCount} ADULT | ${values.childCount} CHILDREN | ${values.infantCount} INFANTS  `;
  return message;
};

export const createDuplicateBookingFoundMessage = (
  values: {
    adultCount: number;
    childCount: number;
    infantCount: number;
  },
  oldBooking: any,
): string => {
  let message = `${nameOfUser} tried to  added passenger to the booking  - ${values.adultCount} ADULT | ${values.childCount} CHILDREN | ${values.infantCount} INFANTS - but a duplicated booking ${oldBooking?._id} found `;
  return message;
};

export const createProceededWithDuplicateBookingAndAddedPassengersInBookingMessage =
  (values: {
    adultCount: number;
    childCount: number;
    infantCount: number;
  }): string => {
    let message = `${nameOfUser} viewed the old booking alert and choose to proceed with adding passenger to the booking  - ${values.adultCount} ADULT | ${values.childCount} CHILDREN | ${values.infantCount} INFANTS  `;
    return message;
  };

export const createProceedWithDuplicateBookingMessage = () => {
  const message = `${nameOfUser} selected Proceed anyways button with duplicated Booking`;
  return message;
};

// ****************************************************  Payment Events  ****************************************************

export const createNavigatedToPaymentPage = () => {
  const message = `${nameOfUser} navigated to Payment Page`;
  return message;
};

export const createUserSelectedPaymentMethod = (
  paymentType: PaymentTypeEnum,
) => {
  const message = `${nameOfUser} selected ${paymentType === PaymentTypeEnum.CREDIT ? 'Wallet Payment' : 'Payment Gateway'}  `;
  return message;
};

export const createConfirmationDialogOpenedMessage = () => {
  const message = `Review dialogue shown to ${nameOfUser}`;
  return message;
};

export const createPaymentModeAndIdentifierMessage = (values: {
  identifier: string;
  paymentMode: string;
}) => {
  const message = `${nameOfUser} selected ${values.paymentMode} of ${values.identifier}`;
  return message;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
