import React, { FC, useEffect, useMemo } from 'react';
import Modal from 'app/components/Modal/Modal';
import { ExclamationMarkIcon } from 'app/icons';
import { formatCurrency } from 'app/utils/currency-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ILoggerBookingEvent } from 'app/types/booking-event';
import {
  createPriceChangeAcceptedMessage,
  createPriceChangeRejectedMessage,
  createPriceDifferenceMessage,
} from 'app/utils/event-utils';

interface IPriceChangedDialog {
  show: boolean;
  prevPrice: number;
  currentPrice: number;
  onProceed: () => void;
  onBackToSearch: () => void;
  createEvent: (payload: ILoggerBookingEvent) => void;
}

const PriceChangedDialog: FC<IPriceChangedDialog> = (props) => {
  const { currentPrice, prevPrice, createEvent } = props;

  const increased = useMemo(() => {
    return currentPrice > prevPrice;
  }, [prevPrice, currentPrice]);

  const label = increased ? (
    <span className='text-red-500'>increased </span>
  ) : (
    <span className='text-green-500'>decreased</span>
  );

  const priceDifference = () => {
    return Math.abs(currentPrice - prevPrice);
  };

  useEffect(() => {
    createEvent({
      message: createPriceDifferenceMessage(prevPrice, currentPrice),
    });
  }, []);

  return (
    <Modal
      className='px-10 pb-6 !w-[600px]'
      show={props.show}
      onClose={props.onProceed}
      onBackdropClick={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col items-center'>
        <ExclamationMarkIcon />
        <div className='text-center text-2xl font-bold '>
          The fare has {label} by {formatCurrency(priceDifference())}
        </div>
      </div>
      <div className='text-base font-normal mt-4'>
        The fare for this booking has changed. Please click the proceed button
        if you wish to accept this change
      </div>
      <div className='flex gap-2 mt-4 text-lg leading-6 font-semibold'>
        <div>Old Fare :</div>
        <div>{formatCurrency(props.prevPrice)}</div>
      </div>
      <div className='flex gap-2 mt-4 text-lg leading-6 font-semibold'>
        <div>New Fare :</div>
        <div>{formatCurrency(props.currentPrice)}</div>
      </div>

      <div className='flex justify-center gap-4 mt-16 px-8  '>
        <LoaderButton
          className='w-full bg-primary text-white px-6 text-base leading-5 font-semibold rounded-lg '
          onClick={() => {
            createPriceChangeAcceptedMessage(props.currentPrice);
            props.onProceed();
          }}
        >
          Proceed
        </LoaderButton>
        <LoaderButton
          className='w-full bg-gray-200   px-6 text-base leading-5 font-semibold rounded-lg'
          onClick={() => {
            createPriceChangeRejectedMessage(props.currentPrice);
            props.onBackToSearch();
          }}
        >
          Back to search
        </LoaderButton>
      </div>
    </Modal>
  );
};

export default PriceChangedDialog;
