/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import moment from 'moment';
import useDialog from 'app/hooks/use-dialog';
import { formatDate } from 'app/utils/date-utils';
import { useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'app/utils/currency-utils';
import PaymentNotes from '../PaymentNotes/PaymentNotes';
import BookingDetailDialog from '../BookingDetailDialog/BookingDetailDialog';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import PaymentReceiptDialog from '../PaymentReceiptDialog/PaymentReceiptDialog';
import BookingInvoiceDialog from '../BookingInvoiceDialog/BookingInvoiceDialog';
import { ExcelIcon } from 'app/icons';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import Tooltip from 'app/components/Tooltip/Tooltip';
// import BookingInvoice from '../BookingInvoiceDialog/BookingInvoiceDialog';

interface TransactionsProps {
  transactions: any;
}

const transactionTypeMessage: any = {
  ADD_MONEY_TO_WALLET: 'Money added to wallet',
  FLIGHT_BOOKING: 'Flight Booking',
  OPENING_BALANCE: 'Opening Balance',
  COMMISSION: 'Booking Commission',
};

const Transactions: FC<TransactionsProps> = (props) => {
  const { transactions } = props;

  const paymentDialog = useDialog<string>('');
  const bookingDialog = useDialog<string>('');
  const invoiceDialog = useDialog<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  let fromDate = searchParams.get('fromDate') || '';
  let toDate = searchParams.get('toDate') || '';

  const paymentNoteDialog = useDialog<{ _id: string; type: string }>({
    _id: '',
    type: '',
  });

  const getTransactionTypeMessage = (type: string, category: string) => {
    if (type === 'PAYMENT_NOTE') {
      return category === 'CREDIT' ? 'Credit Note' : 'Debit Note';
    } else {
      return transactionTypeMessage[type];
    }
  };

  const getCreditAmountLabel = (
    type: string,
    category: string,
    amount: number,
  ) => {
    if (category === 'OPENING_BALANCE' && amount > 0) {
      return <span className='text-green-500'>{formatCurrency(amount)} </span>;
    } else if (category === 'PAYMENT' || category === 'COMMISSION_PAYMENT') {
      return <span className='text-green-500'>{formatCurrency(amount)} </span>;
    } else if (type === 'PAYMENT_NOTE' && category === 'CREDIT') {
      return <span className='text-green-500'>{formatCurrency(amount)} </span>;
    } else {
      return '-';
    }
  };

  const getDebitAmountLabel = (
    type: string,
    category: string,
    amount: number,
  ) => {
    if (category === 'OPENING_BALANCE' && amount <= 0) {
      return <span className='text-red-500'>{formatCurrency(amount)} </span>;
    } else if (category === 'INVOICE') {
      return <span className='text-red-500'>{formatCurrency(amount)} </span>;
    } else if (type === 'PAYMENT_NOTE' && category === 'DEBIT') {
      return <span className='text-red-500'>{formatCurrency(amount)} </span>;
    } else {
      return '-';
    }
  };

  const handleDialogOpener = (tranType: string, _id: string, ref: any) => {
    if (tranType === 'PAYMENT') {
      paymentDialog.show(_id);
    }

    if (tranType === 'CREDIT' || tranType === 'DEBIT') {
      if (tranType === 'CREDIT') {
        paymentNoteDialog.show({ _id, type: 'Credit' });
      }
      if (tranType === 'DEBIT') {
        paymentNoteDialog.show({ _id, type: 'Debit' });
      }
    }

    if (tranType === 'INVOICE') {
      // window.open(`/bookings/${ref}/invoice`, '_blank');
      // navigate(`/bookings/${ref}/invoice`);
      invoiceDialog.show(ref);
    }

    if (tranType === 'COMMISSION_PAYMENT') {
      bookingDialog.show(ref);
      // navigate(`/bookings/${ref}`);
    }
  };

  const handleDateFilter = (fromDate: string, toDate: string) => {
    fromDate
      ? searchParams.set('fromDate', fromDate)
      : searchParams.delete('fromDate');
    toDate ? searchParams.set('toDate', toDate) : searchParams.delete('toDate');
    setSearchParams(searchParams, { replace: true });
  };

  const handleSubmit = () => {
    paymentDialog.hide();
  };

  const filterData = transactions.filter((transaction: any) => {
    // no filter then return true
    if (fromDate === '' && toDate === '') return true;

    let fromFlag = false;
    let toFlag = false;

    if (fromDate === '') {
      fromFlag = true;
    } else {
      fromFlag = +transaction.createdAt.substr(0, 8) >= +fromDate;
    }

    if (toDate === '') {
      toFlag = true;
    } else {
      toFlag = +transaction.createdAt.substr(0, 8) <= +toDate;
    }

    return toFlag && fromFlag;
  });

  const subAgencyTransactionDownloadMutation = useMutation(async () => {
    const { data } = await http.get(
      // `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/transactions/excel?fromDate=20230101&toDate=2023060`
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/transactions/excel?fromDate=${fromDate === '' ? '20210101' : fromDate}&toDate=${toDate === '' ? moment().format('YYYYMMDD') : toDate}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  });

  const handleDownloadExcel = async () => {
    try {
      const res = await subAgencyTransactionDownloadMutation.mutateAsync();
      const url = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `ledger.xlsx`);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (e: any) {
      toast.error('Something went wrong');
    }
  };

  return (
    <div className=''>
      <div className='flex flex-col w-full sm:flex-row gap-2 items-end sm:items-center sm:justify-end px-4 mt-4 overflow-auto hide-scrollbar'>
        <TableDateFilter onChange={handleDateFilter} />

        <div>
          <button
            className='cursor-pointer flex items-center justify-center rounded-full'
            onClick={handleDownloadExcel}
            disabled={subAgencyTransactionDownloadMutation.isLoading}
          >
            <ExcelIcon width='32' height='32' />
          </button>
        </div>
      </div>

      {/* transaction table */}
      <div className='mt-4 pb-10 overflow-auto'>
        <table className='w-full min-w-[1200px]'>
          <thead>
            <tr>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5 pl-10'>
                Date
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Transaction Type
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Reference ID
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Credit
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>Debit</td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5 text-right pr-10'>
                Balance
              </td>
            </tr>
          </thead>
          <tbody>
            {transactions.length === 0 && (
              <tr>
                <td
                  align='center'
                  colSpan={10}
                  className='py-12 font-medium text-base text-[#98A2B3]'
                >
                  - No data available -
                </td>
              </tr>
            )}
            {/* transactions */}
            {filterData.map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td className='text-sm font-normal py-4 pl-10'>
                    {`${formatDate(row.createdAt)} ${moment(
                      row.createdAt,
                      'YYYYMMDDHHmmss',
                    ).format('hh:mm a')}`}{' '}
                  </td>

                  <td className='text-sm font-normal py-4 '>
                    <div className='font-bold'>
                      {getTransactionTypeMessage(row.type, row.category)}
                    </div>
                    <div className='text-xs mt-2 '>
                      {row.category === 'INVOICE' && (
                        <Tooltip
                          content={
                            <div className='w-56 h-32 p-4'>
                              <div className='font-semibold'>Passenger(s)</div>
                              <ul className='text-sm'>
                                {row.metadata?.passengerNames.map(
                                  (pax: string, index: number) => (
                                    <li>
                                      {' '}
                                      {index + 1}) {pax}
                                    </li>
                                  ),
                                )}
                              </ul>
                            </div>
                          }
                        >
                          {`${row.metadata?.segment} / ${row.metadata?.departureDate ? moment(row.metadata.departureDate, 'YYYYMMDD').format('DD MMM, YYYY') : ' - '} / ${row.metadata?.pnr} / ${row.metadata?.passengerNames.length} Pax `}
                        </Tooltip>
                      )}
                    </div>
                  </td>

                  <td className='text-sm font-normal py-4 '>
                    {row._id || '-'}
                    <a
                      onClick={() =>
                        handleDialogOpener(
                          row.category,
                          row._id,
                          row.referenceId,
                        )
                      }
                      className='text-blue-500 ml-2 cursor-pointer'
                    >
                      {row.category === 'PAYMENT' ? 'View Receipt' : ''}
                      {row.category === 'INVOICE' ? 'View Invoice' : ''}
                      {row.category === 'COMMISSION_PAYMENT'
                        ? 'View Booking'
                        : ''}
                      {row.category === 'CREDIT' && row.type === 'PAYMENT_NOTE'
                        ? 'View  Credit Note'
                        : row.category === 'DEBIT' &&
                            row.type === 'PAYMENT_NOTE'
                          ? 'View Debit Note'
                          : ''}
                    </a>
                  </td>
                  <td className='text-sm font-semibold py-4'>
                    {getCreditAmountLabel(row.type, row.category, row.amount)}
                  </td>
                  <td className='text-sm font-semibold py-4'>
                    {getDebitAmountLabel(row.type, row.category, row.amount)}
                  </td>
                  <td className='text-sm font-semibold py-4 text-right pr-10'>
                    {row.balance < 0 && '-'} {formatCurrency(row.balance)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <PaymentReceiptDialog
        show={paymentDialog.isOpen}
        onClose={paymentDialog.hide}
        onSubmit={handleSubmit}
        transactionId={paymentDialog.data}
      />

      <PaymentNotes
        show={paymentNoteDialog.isOpen}
        onClose={paymentNoteDialog.hide}
        onSubmit={handleSubmit}
        type={paymentNoteDialog.data.type}
        transactionId={paymentNoteDialog.data._id}
      />

      {bookingDialog.isOpen && (
        <BookingDetailDialog
          show={bookingDialog.isOpen}
          onClose={bookingDialog.hide}
          bookingId={bookingDialog.data}
        />
      )}
      {invoiceDialog.isOpen && (
        <BookingInvoiceDialog
          show={invoiceDialog.isOpen}
          onClose={invoiceDialog.hide}
          bookingId={invoiceDialog.data}
        />
      )}
    </div>
  );
};

export default Transactions;
