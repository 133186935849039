import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { hexToRgb } from 'app/utils/color-utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { BackIcon } from 'app/icons';
import styles from './ForgetPassword.module.scss';
import Color from 'color';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import StaticNav from 'app/components/StaticNav/StaticNav';
import noAuthHttp from 'app/config/noAuthHttp';

const forgotSchema = Yup.object({
  username: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required'),
});

interface ForgetPasswordProps {}

const ForgetPassword: FC<ForgetPasswordProps> = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    logo: '',
    tagline: '',
    staticPages: [],
  });

  const loginQuery = useQuery(
    ['loginDetails'],
    async () => {
      const { data } = await noAuthHttp.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/details`,
        {
          domain: window.location.hostname,
          // domain: 'sub-agent.farepod.co',  // example is here
        },
      );
      return data;
    },
    {
      retry: 0,
    },
  );

  // Set Data
  useEffect(() => {
    if (loginQuery.data) {
      const {
        name,
        email,
        phoneNumber,
        logoUrl,
        iconUrl,
        tagline,
        primaryColor,
        secondaryColor,
        staticPages,
      } = loginQuery.data;

      setData({
        name: name,
        logo: logoUrl,
        tagline: tagline,
        email: email,
        phoneNumber: phoneNumber,
        staticPages: staticPages,
      });

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }

      // set title
      document.title = name + ' - ' + tagline;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
  }, [loginQuery.data]);

  const forgetMutation = useMutation(async (values: any) => {
    const { data } = await noAuthHttp.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/forgot-password`,
      values,
    );

    return data;
  });

  const handleSubmit = async (values: any) => {
    try {
      const data = await forgetMutation.mutateAsync({
        username: values.username,
        domain: window.location.hostname,
      });
      navigate(`/password/reset/${data._id}`);
      // navigate('')
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img
            src={data.logo}
            className='h-12'
            alt=''
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
        </div>

        <div className=' flex justify-center  gap-0 md:gap-6 '>
          <div
            className={`${styles['card-shadow']}  card rounded-lg  gap-3 overflow-hidden mt-10 w-[600px] `}
          >
            <div className=' px-10 py-10  '>
              {/* Form Heading */}
              <div className='mb-5'>
                <Link
                  className='inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900'
                  to='/'
                >
                  <BackIcon height={12} />
                  <span className='ms-2'>Back</span>
                </Link>
              </div>
              <h3 className='font-bold'>Reset Password</h3>
              <div className='text-base text-gray-800 font-light mt-3'>
                {/* Please enter the following details to reset your password */}
                Enter your registered email address. We will send you an OTP for
                verification.
              </div>
              <Formik
                initialValues={{ username: '' }}
                validationSchema={forgotSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <div>
                      {/* Agency Details */}
                      <div className='mt-8'>
                        <div
                        //  className="grid  sm:grid-cols-2 sm:gap-7"
                        >
                          {/* left */}
                          <div>
                            <div className='relative mt-4'>
                              <Field
                                id='username'
                                name='username'
                                className='border-2 border-[#ECE9E9] w-full py-4 px-4 rounded-md'
                                type='email'
                              />
                              <div className='form-error'>
                                <ErrorMessage name='username' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='mt-20'>
                        <div
                        // className="grid grid-cols-1 sm:grid-cols-2 gap-7"
                        >
                          <LoaderButton
                            disabled={false}
                            type='submit'
                            className='bg-primary text-white text-sm font-semibold rounded-lg w-full'
                            isLoading={forgetMutation.isLoading}
                          >
                            Verify account
                          </LoaderButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {/* <div
                        className={`${styles['vertical-text']} mt-96 text-3xl font-bold text-secondary-fg hidden sm:block`}
                    >
                        {data.tagline}
                    </div> */}
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

export default ForgetPassword;
