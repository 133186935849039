import { FC } from 'react';
import moment from 'moment';
import contryList from 'country-list';
import { IBooking, IBookingPassenger } from 'app/types';
import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';

import { DOB_MANDATORY } from 'app/config';
import { extractFieldFromBooking } from '../../utils/booking-utils';
import Card from 'app/components/shared/Card/Card';

interface IPassengerDetails {
  booking: IBooking;
}

const PassengerDetails: FC<IPassengerDetails> = (props) => {
  const { booking } = props;

  const { isInternational } = extractFieldFromBooking(booking);

  return (
    <Card className='card px-8 py-7 '>
      <div className='text-2xl font-bold mb-8'>Traveller Details </div>

      <div className='overflow-auto no-scrollbar'>
        <div className='grid grid-cols-5 gap-8 mb-4 min-w-[650px]'>
          <div className='col-start-2 col-span-1'>
            <div className='text-xs font-normal text-gray-500'>Name</div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>Airline PNR</div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>
              eTicket Number
            </div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>Seat Number</div>
          </div>
        </div>

        {booking.passengers.adults.map((row, i) => (
          <PassengerDetailRow
            index={i}
            row={row}
            type={'Adult'}
            key={`${i} adult `}
            isInternational={isInternational}
          />
        ))}

        {booking.passengers.children.map((row, i) => (
          <PassengerDetailRow
            index={i}
            row={row}
            type={'Child'}
            key={i}
            isInternational={isInternational}
          />
        ))}

        {booking.passengers.infants.map((row, i) => (
          <PassengerDetailRow
            index={i}
            row={row}
            type={'Infant'}
            key={i}
            isInternational={isInternational}
          />
        ))}

        <div className='grid grid-cols-5 gap-8 mt-8 mb-4 min-w-[650px]'>
          <div className='col-start-2 col-span-1'>
            <div className='text-xs font-normal text-gray-500'>
              Mobile Number
            </div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>
              Email Address
            </div>
          </div>
        </div>

        <div className='grid grid-cols-5 gap-8  min-w-[650px] '>
          <div className='col-span-1'>
            <div className='text-lg font-semibold'>Contact</div>
          </div>
          <div className='col-span-1'>
            <div className='text-lg font-semibold'>
              {booking.contactDetails.mobileNumber}
            </div>
          </div>
          <div className='col-span-1'>
            <div className='text-lg font-semibold'>
              {booking.contactDetails.email}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PassengerDetails;

interface PassengerDetailRowProps {
  index: number;
  type: string;
  row: IBookingPassenger;
  isInternational: boolean;
}

const PassengerDetailRow: FC<PassengerDetailRowProps> = (props) => {
  const { index, row, type, isInternational } = props;
  return (
    <>
      <div key={index} className='grid grid-cols-5 gap-8  min-w-[650px]'>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>{`${type} ${index + 1}`}</div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>
            {row.title} {row.firstName} {row.lastName}
            <div className='text-xs font-normal'>
              {getGenderFromTitle(row.title)}, {type}
            </div>
            {(type === 'Infant' || isInternational || DOB_MANDATORY) &&
              row.dob && (
                <div className='text-xs font-normal'>
                  (DOB - {formatDOB(row.dob, 'YYYY-MM-DD')})
                </div>
              )}
          </div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>{row.pnrs[0]?.number}</div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>
            {row.ticketNumbers[0]?.number || ''}
          </div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>-</div>
        </div>
      </div>
      {isInternational && (
        <div
          key={index}
          className='grid grid-cols-5 gap-8 mb-2 min-w-[650px] text-xs mt-1'
        >
          <div className='col-span-1'></div>
          <div className='col-span-1 flex flex-col justify-between'>
            <div className='text-xs font-normal text-gray-500'>
              Nationality :
            </div>
            <div className='text-sm font-normal'>
              {' '}
              {contryList.getName(row.passport.nationality)}{' '}
            </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Passport :</div>
            <div className='text-sm font-normal'> {row.passport.number} </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Issue :</div>
            <div className='text-sm font-normal'>
              {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
            </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Expiry :</div>
            {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
          </div>
        </div>
      )}
    </>
  );
};
