import moment from 'moment';
import http from 'app/config/http';
import countryList from 'country-list';
import { EditPencilIcon } from 'app/icons';
import capitalize from 'lodash.capitalize';
import { IPassenger } from './types/Passenger';
import { useQuery } from '@tanstack/react-query';
import { getInitials } from 'app/utils/common-utils';
import { Link, useNavigate } from 'react-router-dom';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';

interface PassengerQueryResponse {
  customers: IPassenger[];
  page: number;
  results: number;
  size: number;
}

const SavedPassengerList = () => {
  const navigate = useNavigate();
  const { data: savedPassengerList, isLoading } = useQuery(
    ['Passengers'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/customers?page=1&size=50`,
      );

      return (data as PassengerQueryResponse).customers;
    },
  );

  if (isLoading) return <PageLoader />;

  return (
    <div>
      <MobileTopBar />
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Passengers</div>
        </div>

        <div className='card py-9'>
          {/* card heading  */}
          <div className='mx-9 pt-9 flex justify-between items-end'>
            <div className='text-xl font-bold'>Passengers</div>
            <Link
              to={'/my-agency/Passengers/add'}
              className='text-sm font-semibold text-blue-500'
            >
              Add Passenger
            </Link>
          </div>

          <div className='overflow-auto mt-8'>
            <table className='w-full'>
              <thead>
                <tr>
                  <td className='td th'>Name</td>
                  <td className='td th'>Date of Birth</td>
                  <td className='td th'>Type</td>
                  <td className='td th'>Passport Number</td>
                  <td className='td th'>Nationality</td>
                  <td className='td th'></td>
                </tr>
              </thead>
              <tbody>
                {savedPassengerList.map((passenger, index) => {
                  return (
                    <tr key={index}>
                      <td className='td tb'>
                        <div
                          className='flex gap-3 items-center cursor-pointer'
                          onClick={() =>
                            navigate(`/my-agency/Passengers/${passenger._id}`)
                          }
                        >
                          <div className='flex flex-col gap-1 '>
                            <div className='w-14 h-14 bg-primary/10 rounded-full flex items-center justify-center'>
                              <div className='text-primary text-lg font-semibold'>
                                {getInitials(passenger.firstName)}
                              </div>
                            </div>
                          </div>

                          <div className='text-sm   text-blue-500'>
                            {`${passenger.title}.  ${passenger.firstName} ${passenger.lastName}`}
                          </div>

                          {/* <div className='text-sm flex flex-col gap-0 text-blue-500'>
                            <div>
                              {`${passenger.title}.  ${passenger.firstName}`}
                            </div>
                            <div>{passenger.lastName}</div>
                          </div> */}
                        </div>
                      </td>
                      <td className='td tb'>
                        {passenger.dob.length !== 0
                          ? moment(passenger.dob, 'YYYYMMDD').format(
                              'DD MMM YYYY',
                            )
                          : '-'}
                      </td>
                      <td className='td tb'>{capitalize(passenger.paxType)}</td>
                      <td className='td tb'>
                        {passenger.passport.number === ''
                          ? '-'
                          : passenger.passport.number}
                      </td>
                      <td className='td tb'>
                        {passenger.passport.nationality &&
                        passenger.passport.nationality === ''
                          ? '-'
                          : countryList.getName(passenger.passport.nationality)}
                      </td>

                      <td className=' td tb  text-success'>
                        <EditPencilIcon
                          onClick={() =>
                            navigate(`/my-agency/Passengers/${passenger._id}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedPassengerList;
