import SideDrawer from 'app/components/SideDrawer/SideDrawer';
import { InfoCircleIcon } from 'app/icons';
import { IBooking } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import { FC } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface IFareSummarySideDrawer {
  booking: IBooking;
  show: boolean;
  onClose: () => void;
}

const FareSummarySideDrawer: FC<IFareSummarySideDrawer> = (props) => {
  const { booking, show, onClose } = props;

  if (booking.isTaxed) {
    return (
      <SideDrawer show={show} onClose={onClose} className='w-[500px] px-6'>
        <div>
          <div className='text-lg font-bold relative -top-3'>Fare Details</div>
          <div className='flex justify-between items-center text-sm'>
            <div>Base Fare</div>
            <div>{formatCurrency(booking.taxDetails.totalBaseFare)}</div>
          </div>
          <div className='flex justify-between items-center text-sm mt-4'>
            <div>Fee & Surcharges</div>
            <div>
              {formatCurrency(
                booking.taxDetails.totalTaxesAndFees +
                  booking.priceDetail.additionalMarkup,
              )}
            </div>
          </div>
          <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
            <div>Original Fare</div>
            <div>
              {formatCurrency(
                booking.taxDetails.originalFare +
                  booking.priceDetail.additionalMarkup,
              )}
            </div>
          </div>
          <div className='flex justify-between items-center text-sm mt-4'>
            <div>Mgt. Fee</div>
            <div>{formatCurrency(booking.taxDetails.managementFees)}</div>
          </div>
          <div className='flex justify-between items-center text-sm mt-4 '>
            <div>Mgt. Fee GST</div>
            <div>{formatCurrency(booking.taxDetails.managementFeesGST)}</div>
          </div>
          <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
            <div>Mgt. Fee Total</div>
            <div>{formatCurrency(booking.taxDetails.managementFeesTotal)}</div>
          </div>
          <div className='font-semibold flex justify-between items-center mt-8'>
            <div>Total Amount</div>
            <div>
              {formatCurrency(
                booking.taxDetails.totalFare +
                  booking.priceDetail.additionalMarkup,
              )}
            </div>
          </div>
          <div className='mt-8'>
            <div className='flex justify-between items-center text-sm'>
              <div>Commission Total</div>
              <div>{formatCurrency(booking.taxDetails.commissionTotal)}</div>
            </div>
            <div className='flex justify-between items-center text-sm mt-4'>
              <div>T.D.S</div>
              <div>{formatCurrency(booking.taxDetails.commissionTds)}</div>
            </div>
            <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
              <div>Net Commission</div>
              <div>{formatCurrency(booking.taxDetails.commissionNet)}</div>
            </div>

            <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
              <div className='flex gap-2 items-center'>
                <div>Net Fare</div>
                <div data-tooltip-id='my-tooltip-1'>
                  <InfoCircleIcon />
                </div>
              </div>
              <div>
                {formatCurrency(
                  booking.taxDetails.totalFare -
                    booking.taxDetails.commissionNet +
                    booking.priceDetail.additionalMarkup,
                )}
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          id='my-tooltip-1'
          place='bottom'
          content={`Agency Markup    ${formatCurrency(booking.priceDetail.additionalMarkup)}`}
        />
      </SideDrawer>
    );
  }
};

export default FareSummarySideDrawer;
